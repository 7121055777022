import { Analytics, AWSKinesisFirehoseProvider } from '@aws-amplify/analytics'
import { mutations } from './state'

export function initAnalytics() {
  Analytics.configure({
    AWSKinesisFirehose: {
      region: 'ap-northeast-1',
    },
  })
  Analytics.addPluggable(new AWSKinesisFirehoseProvider())
}

interface SendEventArgs {
  type: 'pageview' | 'click'
  url: string
  prevUrl: string
  target: HTMLElement | null
}

function getInnerText(target: HTMLElement | null) {
  try {
    if (target === null) {
      return ''
    }
    return target.innerText === target.innerHTML ? target.innerText : ''
  } catch {
    return ''
  }
}

function getDataTestId(target: HTMLElement | null, level = 0): string {
  try {
    if (target === null) {
      return ''
    }
    const testId = target.getAttribute('data-testid')
    if (testId === null && level < 5) {
      return getDataTestId(target.parentElement, level + 1)
    }
    return testId ?? ''
  } catch {
    return ''
  }
}

function parseUrl(urlStr: string) {
  const parsed = {
    path: '',
    query: '',
    hash: '',
  }
  try {
    if (urlStr !== '') {
      const parsedUrl = new URL(urlStr)
      parsed.path = parsedUrl.pathname
      parsed.query = parsedUrl.searchParams.toString()
      parsed.hash = parsedUrl.hash
    }
    return parsed
  } catch {
    return parsed
  }
}

function generateEventData(args: SendEventArgs) {
  const parsedUrl = parseUrl(args.url)
  const parsedPrevUrl = parseUrl(args.prevUrl)
  const currentInfo = mutations.getCurrentInfo()
  const event = {
    type: args.type,
    ts: Date.now(),
    path: parsedUrl.path,
    query: parsedUrl.query,
    hash: parsedUrl.hash,
    prev_path: parsedPrevUrl.path,
    prev_query: parsedPrevUrl.query,
    prev_hash: parsedPrevUrl.hash,
    test_id: getDataTestId(args.target),
    inner_text: getInnerText(args.target),
    account_id: currentInfo?.accountId ?? '',
    user_id: currentInfo?.userId ?? '',
  }
  return `${JSON.stringify(event)}\n`
}

export async function sendEvent(args: SendEventArgs) {
  const streamName = process.env.NEXT_PUBLIC_AWS_KINESIS_ANALYTICS_STREAM_NAME ?? ''
  if (streamName === '') {
    return
  }
  try {
    await Analytics.record(
      {
        data: generateEventData(args),
        streamName,
      },
      'AWSKinesisFirehose',
    )
  } catch {}
}
