import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { WorkshopSurveyV1, WorkshopSurveyV2 } from './interfaces'
import {
  DoubleworkEntryUserPermission,
  DoubleworkFlowStepNo,
  EntryUserPermission,
  EntryType,
  FlowStepNo,
  JTFlowStepNo,
  GccFlowStepNo,
  IccFlowStepNo,
  CareerScoutEntryUserPermission,
  CareerScoutEntryType,
  CareerScoutGroupFlowStepNo,
  CareerScoutIndividualFlowStepNo,
  CareerScoutCommonFlowStepNo,
  testApiEndpoint,
  localApiEndpoint,
  RewardAmountType,
  e2eApiEndpoint,
} from './const'
import _, { flatten, isArray } from 'lodash'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

export function jstDayjs(date?: dayjs.ConfigType, format?: dayjs.OptionType, strict?: boolean) {
  return dayjs(date, format, strict).tz('Asia/Tokyo').locale('ja')
}

export function getJSTStartOfDay(date: dayjs.ConfigType): Date {
  return jstDayjs(date).startOf('day').toDate()
}

export function getJSTEndOfDay(date: dayjs.ConfigType): Date {
  return jstDayjs(date).endOf('day').millisecond(0).toDate()
}

export function getJSTCurrentPeriod(current = new Date()) {
  const now = jstDayjs(current)
  const endOfJapanFiscalYear = now.clone().set('month', 2).endOf('month')
  // NOTE: 1/1 - 3/31の場合
  if (now.isBefore(endOfJapanFiscalYear)) {
    return {
      from: now.clone().add(-1, 'year').set('month', 9).startOf('month'),
      to: endOfJapanFiscalYear,
    }
  }
  const endOfFirstHarf = now.clone().set('month', 8).endOf('month')
  // NOTE: 4/1 - 9/30の場合
  if (now.isBefore(endOfFirstHarf)) {
    return {
      from: now.clone().set('month', 3).startOf('month'),
      to: endOfFirstHarf,
    }
  }
  // NOTE: 10/1 - 12/31の場合
  return {
    from: now.clone().set('month', 9).startOf('month'),
    to: now.clone().add(1, 'year').set('month', 2).endOf('month'),
  }
}

export function isUpdatedThisMonth(date: dayjs.ConfigType) {
  const targetDate = jstDayjs(date)

  // 当月の開始日と終了日を取得
  const now = jstDayjs(new Date())
  const thisMonthStart = now.startOf('month').tz('Asia/Tokyo')
  const thisMonthEnd = now.endOf('month').tz('Asia/Tokyo')

  return targetDate.isBetween(thisMonthStart, thisMonthEnd, null, '[]')
}

export function formatedJapanDate(date: dayjs.ConfigType, format = 'YYYY年MM月DD日 HH:mm:ss') {
  return jstDayjs(date).format(format)
}

// 会計年度を取得する
export function getFiscalYear(current = new Date()) {
  const now = jstDayjs(current)
  const endOfJapanFiscalYear = now.clone().set('month', 2).endOf('month')
  const year = now.get('year')
  return now.isBefore(endOfJapanFiscalYear) ? year - 1 : year
}

// 引数の期間が含まれる半期をfromとtoのオブジェクトの配列で返す
export function getSemesters(start: Date, end: Date) {
  const semesters = []

  let cursor = getJSTCurrentPeriod(start).from

  const endInJST = getJSTCurrentPeriod(end).to
  while (cursor.isSameOrBefore(endInJST)) {
    const semester = getJSTCurrentPeriod(cursor.toDate())
    semesters.push({ from: semester.from.toDate(), to: semester.to.toDate() })
    cursor = cursor.add(6, 'month')
  }

  return semesters
}

export function idToCursor(id: string | null | undefined, size: number = 12): string | null {
  if (id === '' || id === null || id === undefined) {
    return null
  }
  while (id.length < size) id = '0' + id
  return id
}

export function getCurrentPositionDate() {
  const period = getJSTCurrentPeriod()

  // NOTE: 本番公開前用
  switch (period.to.format('YYYY-MM-DD')) {
    // 2024年下期用
    case '2025-03-31':
      return {
        openStartedAt: new Date('2024-10-23T00:00:00+09:00'),
        openEndedAt: new Date('2025-03-31T23:59:59+09:00'),
        applicationStartedAt: new Date('2024-10-23T00:00:00+09:00'),
        applicationEndedAt: new Date('2024-11-08T23:59:59+09:00'),
        workStartedAt: new Date('2024-11-19T00:00:00+09:00'),
        workEndedAt: new Date('2025-02-19T23:59:59+09:00'),
      }
    // 2024年上期用
    case '2024-09-30':
      return {
        openStartedAt: new Date('2024-05-01T00:00:00+09:00'),
        openEndedAt: new Date('2024-09-30T23:59:59+09:00'),
        applicationStartedAt: new Date('2024-05-01T00:00:00+09:00'),
        applicationEndedAt: new Date('2024-05-23T23:59:59+09:00'),
        workStartedAt: new Date('2023-11-20T00:00:00+09:00'), // JTは行わないので前期の期間のままにしている
        workEndedAt: new Date('2024-02-20T23:59:59+09:00'),
      }
    // 2023年下期用
    case '2024-03-31':
      return {
        openStartedAt: new Date('2023-12-22T00:00:00+09:00'),
        openEndedAt: new Date('2024-01-15T23:59:59+09:00'),
        applicationStartedAt: new Date('2023-12-25T00:00:00+09:00'),
        applicationEndedAt: new Date('2024-01-15T23:59:59+09:00'),
        workStartedAt: new Date('2023-11-20T00:00:00+09:00'),
        workEndedAt: new Date('2024-02-20T23:59:59+09:00'),
      }
    // 2023年上期用
    case '2023-09-30':
      return {
        openStartedAt: new Date('2023-05-01T00:00:00+09:00'),
        openEndedAt: new Date('2023-09-30T23:59:59+09:00'),
        applicationStartedAt: new Date('2023-05-01T00:00:00+09:00'),
        applicationEndedAt: new Date('2023-05-24T23:59:59+09:00'),
        workStartedAt: new Date('2023-06-02T00:00:00+09:00'),
        workEndedAt: new Date('2023-08-31T23:59:59+09:00'),
      }
    // 2022年下期用
    case '2023-03-31':
      return {
        openStartedAt: new Date('2022-10-21T00:00:00+09:00'),
        openEndedAt: new Date('2023-03-31T23:59:59+09:00'),
        applicationStartedAt: new Date('2022-10-21T00:00:00+09:00'),
        applicationEndedAt: new Date('2022-11-10T23:59:59+09:00'),
        workStartedAt: new Date('2022-11-21T00:00:00+09:00'),
        workEndedAt: new Date('2023-02-20T23:59:59+09:00'),
      }
    // 2022年上期用
    case '2022-09-30':
      return {
        openStartedAt: new Date('2022-05-02T00:00:00+09:00'),
        openEndedAt: new Date('2022-09-30T23:59:59+09:00'),
        applicationStartedAt: new Date('2022-05-02T00:00:00+09:00'),
        applicationEndedAt: new Date('2022-05-25T23:59:59+09:00'),
        workStartedAt: new Date('2022-06-07T00:00:00+09:00'),
        workEndedAt: new Date('2022-08-31T23:59:59+09:00'),
      }
    // 2021年下期用
    case '2022-03-31':
      return {
        openStartedAt: new Date('2021-10-20T00:00:00+09:00'),
        openEndedAt: new Date('2022-03-31T23:59:59+09:00'),
        applicationStartedAt: new Date('2021-10-20T00:00:00+09:00'),
        applicationEndedAt: new Date('2021-11-08T23:59:59+09:00'),
        workStartedAt: new Date('2021-11-18T00:00:00+09:00'),
        workEndedAt: new Date('2022-02-18T23:59:59+09:00'),
      }
    // NOTE: ポジションのデフォルト応募期間・公開期間・期間(JT)の確認・修正(半期ごと)
    // https://persol-epdndo.docbase.io/posts/2118358#%E3%83%9D%E3%82%B8%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88%E5%BF%9C%E5%8B%9F%E6%9C%9F%E9%96%93%E5%85%AC%E9%96%8B%E6%9C%9F%E9%96%93%E3%81%AE%E4%BF%AE%E6%AD%A3%E5%8D%8A%E6%9C%9F%E3%81%94%E3%81%A8
    default: {
      const openAndApplicationStartAt = period.from.clone().add(1, 'month').add(2, 'week').set('day', 1)
      const periodStartAt = period.from.clone().add(2, 'month').set('day', 4)
      return {
        openStartedAt: getJSTStartOfDay(openAndApplicationStartAt.clone().toDate()),
        openEndedAt: getJSTEndOfDay(period.to.clone().toDate()),
        applicationStartedAt: getJSTStartOfDay(openAndApplicationStartAt.clone().toDate()),
        applicationEndedAt: getJSTEndOfDay(openAndApplicationStartAt.add(1, 'week').set('day', 5).toDate()),
        workStartedAt: getJSTStartOfDay(periodStartAt.toDate()),
        workEndedAt: getJSTEndOfDay(periodStartAt.clone().add(2, 'month').endOf('month').toDate()),
      }
    }
  }
}

// NOTE: E2Eテスト用
export function getTestCurrentPositionDate() {
  const period = getJSTCurrentPeriod()
  return {
    openStartedAt: getJSTStartOfDay(period.from),
    openEndedAt: getJSTEndOfDay(period.to),
    applicationStartedAt: getJSTStartOfDay(period.from),
    applicationEndedAt: getJSTEndOfDay(period.to),
    workStartedAt: getJSTStartOfDay(period.from),
    workEndedAt: getJSTEndOfDay(period.to),
  }
}

export function getSheetColumnLetter(num: number): string {
  const previousLetters = num >= 26 ? getSheetColumnLetter(Math.floor(num / 26) - 1) : ''
  const lastLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26]
  return `${previousLetters}${lastLetter}`
}

export function emailToCognitoUsername(email: string) {
  return email.replace('@', '/')
}

export async function sleep(ms: number) {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

export function byteToMB(bytes: number, decimals = 0) {
  return (bytes / Math.pow(1024, 2)).toFixed(decimals)
}

export function getPeriodName(period: string) {
  const month = period.substring(4, 6)
  if (period.length !== 6 || !jstDayjs(period).isValid() || !['04', '10'].includes(month)) {
    throw new Error('正しくないperiodです')
  }

  return `${period.substring(0, 4)}年${month === '04' ? '上期' : '下期'}`
}

export function getCurrentWorkshopEntryPeriod() {
  return getJSTCurrentPeriod().from.format('YYYYMM')
}

export function getPreviousWorkshopEntryPeriod() {
  const subSixMonth = jstDayjs().subtract(6, 'month').toDate()
  return getJSTCurrentPeriod(subSixMonth).from.format('YYYYMM')
}

export function getDefaultWorkshopSurvey(
  period: string,
  isManager: boolean,
): WorkshopSurveyV1 | WorkshopSurveyV2 | null {
  // 2022年下期の前はSurvey項目が存在しなかったためnull
  if (period < '202210') {
    return null
  }

  if (isManager) {
    return {
      version: 2,
      thinkAboutCareer: '',
      verbalizeCareer: '',
      whyNotVerbalizeCareer: '',
      whyNotVerbalizeCareerOthers: '',
      howManySubordinatesThinkAboutCareer: '',
      difficultCareerSupportCases: '',
    }
  }

  return {
    version: 1,
    thinkAboutCareer: '',
    verbalizeCareer: '',
    whyNotVerbalizeCareer: '',
    whyNotVerbalizeCareerOthers: '',
    reason: [],
    reasonOthers: '',
  }
}

// NOTE: CC/JTの権限と現在のStepを引数に変更可能なStepの配列を返す関数
export const getCanUpdateFlowSteps = (
  permissions: EntryUserPermission[],
  entryType: EntryType,
  fromStep: JTFlowStepNo | GccFlowStepNo | IccFlowStepNo,
): Array<GccFlowStepNo | IccFlowStepNo | JTFlowStepNo> => {
  const flowStepNo =
    entryType === EntryType.GroupCareerCharenge
      ? GccFlowStepNo
      : entryType === EntryType.IndividualCareerCharenge
      ? IccFlowStepNo
      : entryType === EntryType.JobTrial
      ? JTFlowStepNo
      : {}
  const stepNos: Array<GccFlowStepNo | IccFlowStepNo | JTFlowStepNo> = flatten(Object.values(flowStepNo))

  // NOTE: グループ人事、個社人事権限がない場合、変更不可
  if (![EntryUserPermission.AccountAdmin, EntryUserPermission.Admin].some(x => permissions.includes(x))) {
    return []
  }

  // NOTE: 現在と同じステップへの変更は不可
  const returnStepNos = stepNos.filter(x => x !== fromStep)

  // NOTE: ICC/JTの場合
  if (entryType === EntryType.IndividualCareerCharenge || entryType === EntryType.JobTrial) {
    // グループ人事の場合、すべてのステップ変更が可能
    if (permissions.includes(EntryUserPermission.AccountAdmin)) {
      return returnStepNos
    }
    // 個社人事の場合
    // JTの差し戻しの場合、移動不可(巻き戻しは不可)
    if (entryType === EntryType.JobTrial && fromStep === JTFlowStepNo.SendBack) {
      return []
    }
    // 「不合格、落選(1500」未満の場合、巻き戻しは不可
    if (fromStep < FlowStepNo.Rejected) {
      return returnStepNos.filter(x => x > fromStep)
    }
    // 「不合格、落選(1500」以降の場合、「不合格、落選(1500」以降へ変更可(差し戻しを含む)
    return returnStepNos.filter(x => x >= FlowStepNo.Rejected)
  }

  // NOTE: 以下、GCCの場合
  // NOTE: グループ人事の場合「内定承諾」「内定承諾(出向)」「内定辞退」以外は変更可
  if (permissions.includes(EntryUserPermission.AccountAdmin)) {
    // NOTE: 「不合格(1500」以降であれば好きなステップへ変更可
    if (fromStep >= FlowStepNo.Rejected) {
      return returnStepNos
    }
    return returnStepNos.filter(x => x < FlowStepNo.Done || x > GccFlowStepNo.OfferDeclined)
  }
  // 個社人事の場合、オファー面談から本人確認、本人確認（出向）のみ変更可能
  if (fromStep === GccFlowStepNo.Offer) {
    return [GccFlowStepNo.BeforeAgreed, GccFlowStepNo.BeforeAgreedSecondment]
  }

  return []
}

// NOTE: 複業の権限と現在のStepを引数に変更可能なStepの配列を返す関数
export const getCanUpdateDoubleworkFlowSteps = (
  entryUserPermissions: DoubleworkEntryUserPermission[],
  fromStep: DoubleworkFlowStepNo, // 変更前のステップのno
): DoubleworkFlowStepNo[] => {
  // 契約状況確認、契約継続未確認については移動先としても移動元としても操作不可
  const allowedDoubleworkFlowStepNo = Object.values(DoubleworkFlowStepNo).filter(
    x =>
      !([
        DoubleworkFlowStepNo.ContractConfirmation,
        DoubleworkFlowStepNo.ContractContinuationUnapproved,
      ] as DoubleworkFlowStepNo[]).includes(x),
  )

  // 現在のステップが変更可能なステップではない場合はステップ変更不可
  if (!allowedDoubleworkFlowStepNo.includes(fromStep)) {
    return []
  }

  // 現在と同じステップへの変更は不可
  let returnStepNos = allowedDoubleworkFlowStepNo.filter(x => x !== fromStep)

  // 1.グループ人事の場合、前のステップから契約準備、契約終了準備をまたぐステップへの変更以外可能
  // - 巻き戻しは可能
  // - 終了扱いである見送り, 辞退, 契約終了(契約準備以降の場合のみ), 否認に移動の場合はSKIP可能
  if (entryUserPermissions.includes(DoubleworkEntryUserPermission.AccountAdmin)) {
    if (fromStep < DoubleworkFlowStepNo.ContractPreparation) {
      return returnStepNos.filter(
        x =>
          x < DoubleworkFlowStepNo.ContractPreparation ||
          x === DoubleworkFlowStepNo.Rejected ||
          x === DoubleworkFlowStepNo.Declined ||
          x === DoubleworkFlowStepNo.Denied,
      )
    }
    if (fromStep < DoubleworkFlowStepNo.ContractTerminationPreparation) {
      return returnStepNos.filter(
        x => x < DoubleworkFlowStepNo.ContractTerminationPreparation || x >= DoubleworkFlowStepNo.Rejected,
      )
    }
    return returnStepNos
  }

  // グループ人事以外は巻き戻しは不可
  returnStepNos = returnStepNos.filter(x => x > fromStep)

  // 2.発注元個社人事、受入責任者、担当者の場合
  if (
    _.intersection(
      [
        DoubleworkEntryUserPermission.PublisherAdmin,
        DoubleworkEntryUserPermission.Trainer,
        DoubleworkEntryUserPermission.ResponsibleUser,
      ],
      entryUserPermissions,
    ).length !== 0
  ) {
    // 承認済みより前からは変更不可
    if (
      !entryUserPermissions.includes(DoubleworkEntryUserPermission.UsersAdmin) &&
      fromStep < DoubleworkFlowStepNo.Approved
    ) {
      return []
    }

    if (fromStep < DoubleworkFlowStepNo.ContractPreparation) {
      // 契約準備前の場合契約準備以降への変更は不可（見送りは可能）
      returnStepNos = returnStepNos.filter(
        x => x < DoubleworkFlowStepNo.ContractPreparation || x === DoubleworkFlowStepNo.Rejected,
      )
    } else if (fromStep < DoubleworkFlowStepNo.ContractTerminationPreparation) {
      // 契約終了準備前の場合契約終了準備以降への変更は不可（契約終了は可能）
      returnStepNos = returnStepNos.filter(
        x => x < DoubleworkFlowStepNo.ContractTerminationPreparation || x === DoubleworkFlowStepNo.ContractTermination,
      )
    } else if (fromStep === DoubleworkFlowStepNo.ContractTerminationPreparation) {
      // 契約終了準備前の場合契約終了にのみ変更可能
      returnStepNos = [DoubleworkFlowStepNo.ContractTermination]
    } else {
      // 終了扱いである見送り、辞退、契約終了からは変更不可
      returnStepNos = []
    }

    // 輩出元個社人事を兼ねている場合で承認済みより前の場合、承認済み,否認へのみ変更可能(誤操作が起こりそうなので一旦輩出元個社人事として承認済みにしてもらう)
    if (
      entryUserPermissions.includes(DoubleworkEntryUserPermission.UsersAdmin) &&
      fromStep < DoubleworkFlowStepNo.Approved
    ) {
      return [DoubleworkFlowStepNo.Approved, DoubleworkFlowStepNo.Denied]
    }
    return returnStepNos
  }

  // 3.輩出元個社人事で現在のステップが承認済みより前の場合、承認済み,否認へのみ変更可能
  if (
    entryUserPermissions.includes(DoubleworkEntryUserPermission.UsersAdmin) &&
    fromStep < DoubleworkFlowStepNo.Approved
  ) {
    return [DoubleworkFlowStepNo.Approved, DoubleworkFlowStepNo.Denied]
  }

  // 4.権限が無い場合、空配列を返す
  return []
}

export const getCanUpdateCareerScoutFlowSteps = (
  permissions: CareerScoutEntryUserPermission[],
  entryType: CareerScoutEntryType,
  fromStep: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo,
  isCareerScoutSpecialApplicationUser: boolean,
): number[] => {
  const stepNos =
    entryType === CareerScoutEntryType.GroupCareerScout
      ? Object.values(CareerScoutGroupFlowStepNo)
      : entryType === CareerScoutEntryType.IndividualCareerScout
      ? Object.values(CareerScoutIndividualFlowStepNo)
      : []

  // NOTE: 現在と同じステップへの変更は不可
  let returnStepNos = stepNos.filter(x => x !== fromStep).sort((a, b) => a - b)

  // グループ人事の場合
  if (permissions.includes(CareerScoutEntryUserPermission.AccountAdmin)) {
    // 現在のステップが「不合格(1500」以降の場合、好きなステップへ変更可(巻き戻すので内定承諾などにも遷移できる)
    if (fromStep >= FlowStepNo.Rejected) {
      return returnStepNos
    }
    // 「内定承諾」「内定承諾(出向)」「内定辞退」以外は変更可
    return _.difference(returnStepNos, [
      CareerScoutCommonFlowStepNo.Done,
      CareerScoutGroupFlowStepNo.DoneSecondment,
      CareerScoutCommonFlowStepNo.OfferDeclined,
    ])
  }

  // 個社人事の場合
  if (permissions.includes(CareerScoutEntryUserPermission.Admin)) {
    // 現在のステップが合格者人事確認で特例申請対象ユーザの場合、ステップ変更できない
    if (fromStep === CareerScoutGroupFlowStepNo.PersonnelVerification && isCareerScoutSpecialApplicationUser) {
      return []
    }

    // 巻き戻しは不可
    returnStepNos = returnStepNos.filter(x => x > fromStep)

    // 選考準備から合格者決定または合格者人事確認までは遷移可能
    if (fromStep < CareerScoutGroupFlowStepNo.PersonnelVerification) {
      // 特例申請対象ユーザの場合かつグループスカウトの場合は合格者人事確認までしか遷移できない
      returnStepNos =
        entryType === CareerScoutEntryType.GroupCareerScout
          ? isCareerScoutSpecialApplicationUser
            ? returnStepNos.filter(x => x <= CareerScoutGroupFlowStepNo.PersonnelVerification)
            : returnStepNos.filter(
                x =>
                  x <= CareerScoutGroupFlowStepNo.QualifiedDecision &&
                  x !== CareerScoutGroupFlowStepNo.PersonnelVerification,
              )
          : returnStepNos.filter(x => x <= CareerScoutIndividualFlowStepNo.QualifiedDecision)
    }

    // 合格者人事確認からは合格者決定に遷移可能
    if (fromStep === CareerScoutGroupFlowStepNo.PersonnelVerification) {
      returnStepNos = [CareerScoutCommonFlowStepNo.QualifiedDecision]
    }
    // 合格者決定からはオファー面談に遷移可能
    if (fromStep === CareerScoutCommonFlowStepNo.QualifiedDecision) {
      returnStepNos = [CareerScoutCommonFlowStepNo.Offer]
    }
    // オファー面談からは本人確認または本人確認(出向)に遷移可能
    if (fromStep === CareerScoutCommonFlowStepNo.Offer) {
      returnStepNos =
        entryType === CareerScoutEntryType.GroupCareerScout
          ? [CareerScoutCommonFlowStepNo.BeforeAgreed, CareerScoutGroupFlowStepNo.BeforeAgreedSecondment]
          : [CareerScoutCommonFlowStepNo.BeforeAgreed]
    }
    // 本人確認以降の場合、候補者側の操作なので遷移不可
    if (fromStep >= CareerScoutCommonFlowStepNo.BeforeAgreed) {
      return []
    }

    // NOTE: 不合格、要件不可、辞退は遷移元が多いため最後にpushしている
    // 合格者決定より前は不合格、要件不可に変更可能
    if (fromStep < CareerScoutCommonFlowStepNo.QualifiedDecision) {
      returnStepNos.push(CareerScoutCommonFlowStepNo.Rejected, CareerScoutCommonFlowStepNo.RequirementsRejected)
    }
    // 本人確認より前は辞退に変更可能
    if (fromStep < CareerScoutCommonFlowStepNo.BeforeAgreed) {
      returnStepNos.push(CareerScoutCommonFlowStepNo.Declined)
    }
    return returnStepNos.sort((a, b) => a - b)
  }

  // 案件担当、作成者(部長以上)の場合
  if (permissions.includes(CareerScoutEntryUserPermission.ResponsibleManager)) {
    // 巻き戻しは不可
    returnStepNos = returnStepNos.filter(x => x > fromStep)

    // 選考準備から面接3までは遷移可能
    returnStepNos = returnStepNos.filter(x => x <= CareerScoutCommonFlowStepNo.Interview3)
    return returnStepNos.sort((a, b) => a - b)
  }

  return []
}

// NOTE: 複業案件の想定報酬額を自動計算する関数
export function calculateEstimatedRewardAmount(
  rewardAmountType: number,
  rewardAmount: number | '',
  estimatedBusinessHours: number | '',
): number {
  // 報酬区分未選択の場合は0
  if (rewardAmountType === RewardAmountType.NotSelected) {
    return 0
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  rewardAmount = rewardAmount || 0
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  estimatedBusinessHours = estimatedBusinessHours || 0

  // 月額の場合
  // 報酬(単位: 万円) × 10000 = 月額報酬総額(単位: 円)
  if (rewardAmountType === RewardAmountType.MonthlyAmount) {
    return Math.ceil(rewardAmount * 10000)
  }

  // 人月の場合
  // 報酬(単位: 万円) × 10000 × 1/160 × 稼働時間(単位: 時間) = 月額報酬総額(単位: 円)
  return Math.ceil(((rewardAmount * 10000) / 160) * estimatedBusinessHours)
}

// ネストされたオブジェクトの全てのkeyを取得する関数
export function getAllKeys(obj: any, prefix = ''): string[] {
  return Object.keys(obj).flatMap(key => {
    const fullPath = prefix !== '' ? `${prefix}.${key}` : key
    // 配列の中のkeyまでは取得しない
    if (typeof obj[key] === 'object' && obj[key] !== null && !isArray(obj[key])) {
      return getAllKeys(obj[key], fullPath)
    } else {
      return fullPath
    }
  })
}

// targetObjectからdefaultObjectに存在するkeyのみpickしたobjectを返す関数
export function getPickedObject(targetObject: any, defaultObject: any) {
  const defaultObjectKeys = getAllKeys(defaultObject)
  return _.pick(targetObject, defaultObjectKeys)
}

export function getYouTubeId(youTubeUrl: string) {
  try {
    const url = new URL(youTubeUrl)
    const vParams = url.searchParams.getAll('v')
    return vParams[0] ?? ''
  } catch (error) {
    return ''
  }
}

export function getCurrentCareerScoutDate(current = new Date()) {
  const period = getJSTCurrentPeriod(current)

  // NOTE: ローカル環境、テスト環境、e2e環境はテスト用の日時を返している
  // テストが本番の日時で書かれているので、NODE_ENV !== 'test'でCIテスト時は回避するようにしている
  const apiEndpoint = process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT ?? ''
  if (process.env.NODE_ENV !== 'test' && [localApiEndpoint, testApiEndpoint, e2eApiEndpoint].includes(apiEndpoint)) {
    return {
      resumeScoutSettingStartAt: new Date('2024-07-01T00:00:00+09:00'),
      resumeScoutSettingEndAt: new Date('2025-03-30T23:59:59+09:00'),
      offerStartedAt: new Date('2024-07-01T00:00:00+09:00'),
      offerEndedAt: new Date('2025-03-30T23:59:59+09:00'),
      applicationStartedAt: new Date('2024-07-01T00:00:00+09:00'),
      applicationEndedAt: new Date('2025-03-30T23:59:59+09:00'),
      selectionEndedAt: new Date('2025-03-30T23:59:59+09:00'),
    }
  }

  switch (period.to.format('YYYY-MM-DD')) {
    // 2024年下期用
    case '2025-03-31':
      return {
        resumeScoutSettingStartAt: new Date('2024-09-17T00:00:00+09:00'),
        resumeScoutSettingEndAt: new Date('2024-10-10T23:59:59+09:00'),
        offerStartedAt: new Date('2024-10-18T00:00:00+09:00'),
        offerEndedAt: new Date('2024-11-08T23:59:59+09:00'),
        applicationStartedAt: new Date('2024-10-18T00:00:00+09:00'),
        applicationEndedAt: new Date('2024-11-11T23:59:59+09:00'),
        selectionEndedAt: new Date('2024-12-24T23:59:59+09:00'),
      }
    // 2024年上期用
    case '2024-09-30':
      return {
        resumeScoutSettingStartAt: new Date('2024-09-17T00:00:00+09:00'),
        resumeScoutSettingEndAt: new Date('2024-10-10T23:59:59+09:00'),
        offerStartedAt: new Date('2024-10-18T00:00:00+09:00'),
        offerEndedAt: new Date('2024-11-08T23:59:59+09:00'),
        applicationStartedAt: new Date('2024-10-18T00:00:00+09:00'),
        applicationEndedAt: new Date('2024-11-11T23:59:59+09:00'),
        selectionEndedAt: new Date('2024-12-24T23:59:59+09:00'),
      }
    // 2023年下期用
    case '2024-03-31':
      return {
        resumeScoutSettingStartAt: new Date('2023-10-03T00:00:00+09:00'),
        resumeScoutSettingEndAt: new Date('2023-10-24T23:59:59+09:00'),
        offerStartedAt: new Date('2023-11-01T00:00:00+09:00'),
        offerEndedAt: new Date('2023-11-27T23:59:59+09:00'),
        applicationStartedAt: new Date('2023-11-01T00:00:00+09:00'),
        applicationEndedAt: new Date('2023-12-04T23:59:59+09:00'),
        selectionEndedAt: new Date('2023-12-18T23:59:59+09:00'),
      }
    default: {
      // リリース前の暫定値
      return {
        resumeScoutSettingStartAt: new Date('2023-07-01T00:00:00+09:00'),
        resumeScoutSettingEndAt: new Date('2023-09-30T23:59:59+09:00'),
        offerStartedAt: new Date('2023-07-01T00:00:00+09:00'),
        offerEndedAt: new Date('2023-09-30T23:59:59+09:00'),
        applicationStartedAt: new Date('2023-07-01T00:00:00+09:00'),
        applicationEndedAt: new Date('2023-09-30T23:59:59+09:00'),
        selectionEndedAt: new Date('2023-09-30T23:59:59+09:00'),
      }
    }
  }
}

export function getUTCString(date: dayjs.Dayjs = dayjs()) {
  return date.utc().format('YYYY-MM-DD HH:mm:ss')
}

export function getFormattedDateTimeWithHyphen(date: dayjs.Dayjs = dayjs()) {
  return date.format('YYYY-MM-DD-HH:mm')
}

export function indexToAlphabet(index: number) {
  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]
  let result = index
  let string = ''
  while (result >= 0) {
    string = alphabets[result % 26] + string
    result = Math.floor(result / 26 - 1)
  }
  return string
}

export function getTypeName(value: any): string {
  const match = Object.prototype.toString.call(value).match(/\[object (.*?)\]/)
  return match === null ? '' : match[1]
}

export function validateUrl(url: string, handler: () => void) {
  const urlPattern = /^(https?:\/\/(?:[a-zA-Z0-9\-._~%]+)+(?::[0-9]+)?(?:\/[^\s]*)?)$/
  if (!urlPattern.test(url)) {
    handler()
  }
  return url
}
