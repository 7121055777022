import { useRouter } from 'next/router'
import React, { createContext, useEffect, useContext } from 'react'
import { globalState } from '../util'

// NOTE: HistoryProviderの中でuseStateを利用してhistoryを管理したほうが良さそうだが、
// stateの場合原因不明でリセットされるのでglobalでhistory設定している
let history: string[] = []
const HistoryContext = createContext<string[]>(history)

export function useHistory(): string[] {
  const context = useContext(HistoryContext)
  return context
}

interface HistoryProviderProps {
  maxSize?: number
  children: JSX.Element
}

export const HistoryProvider = ({ children, maxSize = 50 }: HistoryProviderProps): JSX.Element => {
  const { asPath, pathname } = useRouter()

  useEffect(() => {
    history = [asPath, ...history].slice(0, maxSize)
    // globalStateのリセット
    Object.values(globalState).forEach(x => {
      if (!x.usePaths.includes(pathname)) {
        x.value = ''
      }
    })
  }, [asPath])

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
}
