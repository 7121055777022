import React, { createContext, useContext } from 'react'

let targetElement: HTMLElement | null = null
const scrollToTop = function () {
  window.scrollTo(0, 0)
  if (targetElement !== null) {
    targetElement.scrollTo(0, 0)
  }
}

const ScrollContext = createContext({ scrollToTop })

export function useScroll(element: HTMLElement | null = null) {
  targetElement = element
  return useContext(ScrollContext)
}

export const ScrollProvider = (props: { children: JSX.Element }): JSX.Element => {
  return <ScrollContext.Provider value={{ scrollToTop }}>{props.children}</ScrollContext.Provider>
}
