import {
  InventoryOfOwnResourcesV1,
  LookingBackOnGrowthV1,
  NextStepV2,
  VectorAndVisionV2,
  ValueCheckV1,
  LifeLineChartV2,
  LifeLineChartV1,
  NextStepV1,
  VectorAndVisionV1,
} from './interfaces'

export const ServiceDeskEmail = 'g-sdesk@persol.co.jp'

export const RELEASE_AD_AUTH_AT = new Date('2021-08-31T15:00:00.000Z')

export const ValidateRegExps = {
  Email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, // メールアドレス形式
  ZenkakuKatakana: /^[ァ-ンヴー]*$/, // 全角カタカナ
  HankakuNumber: /^[0-9]*$/, // 半角数字(空文字OK)
  HankakuNumberRequired: /^[0-9]+$/, // 半角数字(空文字NG)
  ToTwoDecimalPlaces: /^\d+(\.\d{0,2})?$/, // 小数点以下2位まで
  FacebookUrl: /^(?=https:\/\/www\.facebook\.com\/).*$|^$/,
  TwitterUrl: /^(?=https:\/\/twitter\.com\/).*$|^$/,
  LinkedinUrl: /^(?=https:\/\/www\.linkedin\.com\/).*$|^$/,
  GithubUrl: /^(?=https:\/\/github\.com\/).*$|^$/,
  positiveIntegerExcludingZero: /^([1-9][0-9]*)$|^$/, // 0を除く正の整数
}

export const PositionType = {
  NotSelected: 0,
  CareerCharenge: 1,
  JobTrial: 2,
} as const
export type PositionType = typeof PositionType[keyof typeof PositionType]

export const AnnualScheduleType = {
  NotSelected: 0,
  GroupCareerChallenge: 1,
  JobTrial: 2,
  CareerScout: 3,
  IndividualCareerChallenge: 4,
} as const
export type AnnualScheduleType = typeof AnnualScheduleType[keyof typeof AnnualScheduleType]

export const PositionStatus = {
  TemporarilySaved: 1,
  BeforeApproval: 2,
  AfterApproval: 3,
  PostingSuspended: 4,
} as const
export type PositionStatus = typeof PositionStatus[keyof typeof PositionStatus]

export const WorkType = {
  NotSelected: 0,
  BusinessSupport: 1,
  WorkingOnTheTheme: 2,
  PlanningSupport: 3,
  SurveySupport: 4,
  OperationalSupport: 5,
  ObserveSupport: 6,
  OneOnOne: 7,
} as const
export type WorkType = typeof WorkType[keyof typeof WorkType]

export const WorkTypeName: { [key: string]: string } = {
  [WorkType.BusinessSupport]: '実業務サポート（複合）',
  [WorkType.WorkingOnTheTheme]: '参加者主体で進める課題解決PJT',
  [WorkType.PlanningSupport]: '実業務サポート（企画提案）',
  [WorkType.SurveySupport]: '実業務サポート（調査）',
  [WorkType.OperationalSupport]: '実業務サポート（運用）',
  [WorkType.ObserveSupport]: '実業務サポート（オブザーブ）',
  [WorkType.OneOnOne]: '1on1',
}

export const EntryType = {
  GroupCareerCharenge: 1,
  IndividualCareerCharenge: 2,
  JobTrial: 3,
} as const
export type EntryType = typeof EntryType[keyof typeof EntryType]

export const MyEntryType = {
  GroupCareerCharenge: 1,
  IndividualCareerCharenge: 2,
  JobTrial: 3,
  Doublework: 4,
  GroupCareerScout: 5,
  IndividualCareerScout: 6,
} as const
export type MyEntryType = typeof MyEntryType[keyof typeof MyEntryType]

export const CareerScoutEntryType = {
  GroupCareerScout: 1,
  IndividualCareerScout: 2,
} as const
export type CareerScoutEntryType = typeof CareerScoutEntryType[keyof typeof CareerScoutEntryType]

export const EntryUserPermission = {
  EntryUser: 1, // エントリーユーザー
  AccountAdmin: 2, // グループ人事
  Admin: 3, // 個社人事
  Interviewer: 4, // 面接官
  ApproveUser: 5, // 承認者(JT)
  TrainerUser: 6, // 受入先責任者(JT)
}
export type EntryUserPermission = typeof EntryUserPermission[keyof typeof EntryUserPermission]

export type EntryMessageVariant = 'send' | 'sendByOther' | 'receive' | 'system'

export const EntryMessageScheduleRole = {
  Admin: 'admin',
  Viewer: 'viewer',
  None: '',
}
export type EntryMessageScheduleRole = typeof EntryMessageScheduleRole[keyof typeof EntryMessageScheduleRole]

export const NotificationType = {
  Common: 1,
  Download: 2,
  Exec: 3,
} as const
export type NotificationType = typeof NotificationType[keyof typeof NotificationType]

export const NotificationTemplate = {
  PositionCreate: 'PositionCreate',
  InterviewerAssign: 'InterviewerAssign',
  InterviewerWithdraw: 'InterviewerWithdraw',
  Entried: 'Entried',
  EntryToApprove: 'EntryToApprove',
  EntryApproved: 'EntryApproved',
  EntrySentBack: 'EntrySentBack',
  EntryDenied: 'EntryDenied',
  InterviewInScheduleAdjustment: 'InterviewInScheduleAdjustment',
  InterviewScheduleAdjusted: 'InterviewScheduleAdjusted',
  InterviewScheduleAdjustedBySA: 'InterviewScheduleAdjustedBySA',
  InterviewEvaluated: 'InterviewEvaluated',
  Offered: 'Offered',
  OfferAccepted: 'OfferAccepted',
  AcceptOffer: 'AcceptOffer',
  OfferDeclined: 'OfferDeclined',
  DeclineOffer: 'DeclineOffer',
  SecondmentOffered: 'SecondmentOffered',
  SecondmentOfferAccepted: 'SecondmentOfferAccepted',
  SecondmentOfferDeclined: 'SecondmentOfferDeclined',
  MessageFromEntryUser: 'MessageFromEntryUser',
  MessageFromRecruitingUser: 'MessageFromRecruitingUser',
  MessageFromAccountAdmin: 'MessageFromAccountAdmin',
  ExportEntriesArchive: 'ExportEntriesArchive',
  Export: 'Export',
  ApprovePositions: 'ApprovePositions',
  SuggestPositionToEntryUser: 'SuggestPositionToEntryUser',
  DoubleworkCreate: 'DoubleworkCreate',
  ApproveDoublework: 'ApproveDoublework',
  DoubleworkEntriedToEntryUser: 'DoubleworkEntriedToEntryUser',
  DoubleworkEntriedToAdmin: 'DoubleworkEntriedToAdmin',
  DoubleworkSelectorAssign: 'DoubleworkSelectorAssign',
  DoubleworkSelectorWithdraw: 'DoubleworkSelectorWithdraw',
  DoubleworkEntryApproved: 'DoubleworkEntryApproved',
  DoubleworkEntryDenied: 'DoubleworkEntryDenied',
  DoubleworkEntryDecisioned: 'DoubleworkEntryDecisioned',
  DoubleworkEntryRejected: 'DoubleworkEntryRejected',
  DoubleworkEntryContractSigned: 'DoubleworkEntryContractSigned',
  DoubleworkEntryContractTerminated: 'DWEntryContractTerminated',
  DoubleworkOfferAccepted: 'DoubleworkOfferAccepted',
  DoubleworkOfferDeclined: 'DoubleworkOfferDeclined',
  DoubleworkMessageFromEntryUser: 'DoubleworkMessageFromEntryUser',
  DoubleworkMessageFromRecruiter: 'DoubleworkMessageFromRecruiter',
  DoubleworkInterviewInScheduleAdjustment: 'DWInterviewInScheduleAdjustment',
  DoubleworkInterviewScheduleAdjusted: 'DWInterviewScheduleAdjusted',
  DoubleworkInterviewScheduleAdjustedBySA: 'DWInterviewScheduleAdjustedBySA',
  DoubleworkInterviewScheduleAdjustedBySAToEU: 'DWISAdjustedBySAToEU',
  DoubleworkMonitoringContractConfirmation: 'DWMonitoringContractConfirmation',
  RemindDoubleworkEntryMonitoring: 'RemindDoubleworkEntryMonitoring',
  DoubleworkEntryMonitoringApprovalRequest: 'DWEMApprovalRequest',
  DoubleworkEntryMonitoringDeclineToUsersAdmin: 'DWEMDeclineToUsersAdmin',
  DoubleworkEntryMonitoringDeclineToPublisher: 'DWEMDeclineToPublisher',
  DoubleworkEntryMonitoringApproval: 'DWEMApproval',
  DoubleworkEntryMonitoringReject: 'DWEMReject',
  CareerScoutResumeOpen: 'CareerScoutResumeOpen',
  CareerScoutCreate: 'CareerScoutCreate',
  ApproveCareerScouts: 'ApproveCareerScouts',
  CareerScoutOffered: 'CareerScoutOffered',
  CareerScoutOfferMessageByOfferer: 'CareerScoutOfferMessageByOfferer',
  CareerScoutOfferMessageByRUser: 'CareerScoutOfferMessageByRUser',
  CareerScoutEntriedToEntryUser: 'CareerScoutEntriedToEntryUser',
  CareerScoutEntriedToAdmin: 'CareerScoutEntriedToAdmin',
  CareerScoutMessageFromRecruitingUser: 'CSMessageFromRecruitingUser',
  CareerScoutMessageFromEntryUser: 'CSMessageFromEntryUser',
  CareerScoutInterviewerAssign: 'CareerScoutInterviewerAssign',
  CareerScoutInterviewerWithdraw: 'CareerScoutInterviewerWithdraw',
  CareerScoutInterviewInScheduleAdjustment: 'CSInterviewInScheduleAdjustment',
  CareerScoutInterviewScheduleAdjusted: 'CSInterviewScheduleAdjusted',
  CareerScoutInterviewScheduleAdjustedBySA: 'CSInterviewScheduleAdjustedBySA',
  CareerScoutInterviewEvaluated: 'CareerScoutInterviewEvaluated',
  CareerScoutTransferOffered: 'CSTransferOffered',
  CareerScoutTransferSecondmentOffered: 'CSTransferSecondmentOffered',
  CareerScoutOfferAcceptedOrDeclined: 'CSOfferAcceptedOrDeclined',
  CareerScoutAcceptOffer: 'CareerScoutAcceptOffer',
  CareerScoutDeclineOffer: 'CareerScoutDeclineOffer',
  CareerScoutAddCandidateListUser: 'CareerScoutAddCandidateListUser',
  CareerScoutDelCandidateListUser: 'CareerScoutDelCandidateListUser',
  SystemAdminExecuteTask: 'SystemAdminExecuteTask',
} as const
export type NotificationTemplate = typeof NotificationTemplate[keyof typeof NotificationTemplate]

export const EmailTemplateType = {
  Common: 1,
  Doublework: 2,
} as const
export type EmailTemplateType = typeof EmailTemplateType[keyof typeof EmailTemplateType]

export const InterviewUserPermission = {
  EntryUser: 1,
  Admin: 2,
  CurrentInterviewer: 3, // 現在Interviewの面接担当者
  EntryInterviewer: 4, // 他のInterviewを含むエントリーの面接担当者
  ResponsibleManager: 5, // CS専用、案件担当、作成者(部長以上)
}
export type InterviewUserPermission = typeof InterviewUserPermission[keyof typeof InterviewUserPermission]

export const InterviewStatus = {
  NotAssinged: 0,
  InScheduleAdjustment: 1,
  ScheduleAdjusted: 2,
  Evaluated: 3,
  Passed: 4,
  Rejected: 5,
  Declined: 6,
} as const
export type InterviewStatus = typeof InterviewStatus[keyof typeof InterviewStatus]

export const InterviewStatusName: { [key: string]: string } = {
  [InterviewStatus.NotAssinged]: 'アサイン待ち',
  [InterviewStatus.InScheduleAdjustment]: '日程調整中',
  [InterviewStatus.ScheduleAdjusted]: '日程調整済',
  [InterviewStatus.Evaluated]: '評価済',
  [InterviewStatus.Passed]: '完了(合格)',
  [InterviewStatus.Rejected]: '完了(不合格)',
  [InterviewStatus.Declined]: '辞退',
}

export const PledgeVersion = {
  Position: '1.0.0',
  Entry: '1.0.0',
  Doublework: '2.0.0',
  DoubleworkEntry: '1.0.0',
  CareerScoutEntry: '1.0.0',
}

export const InterviewResultStatus = {
  Pending: 1,
  Passed: 2,
  Rejected: 3,
} as const
export type InterviewResultStatus = typeof InterviewResultStatus[keyof typeof InterviewResultStatus]

export const InterviewResultStatusName: { [key: string]: string } = {
  [InterviewResultStatus.Pending]: '検討中',
  [InterviewResultStatus.Passed]: '合格',
  [InterviewResultStatus.Rejected]: '不合格',
}

export const InterviewResultColumn = {
  pros: 1,
  cons: 2,
  memo: 3,
} as const
export type InterviewResultColumn = typeof InterviewResultColumn[keyof typeof InterviewResultColumn]

export const InterviewResultColumnName: { [key: string]: string } = {
  [InterviewResultColumn.pros]: '評価ポイント',
  [InterviewResultColumn.cons]: '課題',
  [InterviewResultColumn.memo]: '内部メモ',
}

export const InterviewLocation = {
  OffSite: 1,
  OnSite: 2,
} as const
export type InterviewLocation = typeof InterviewLocation[keyof typeof InterviewLocation]

export const FlowStepNo = {
  RequirementsConfirmation: 100, // 要件確認
  Done: 1000, // リフレクション、内定承諾、異動承諾
  Rejected: 1500, // 不合格、落選
  Declined: 1520, // 辞退
  RequirementsRejected: 1530, // 要件不可
} as const
export type FlowStepNo = typeof FlowStepNo[keyof typeof FlowStepNo]

export const JTFlowStepNo = {
  ...FlowStepNo,
  Unapproved: 0, // 承認待ち
  ParticipantConfirmation: 510, // 参加者確認
  ParticipantConfirmed: 520, // 参加者確定
  KickOff: 530, // キックオフ
  Survey: 540, // アンケート
  Survey2: 550, // アンケート2
  Denied: 1510, // 否認
  SendBack: 1600, // 差し戻し
} as const
export type JTFlowStepNo = typeof JTFlowStepNo[keyof typeof JTFlowStepNo]

export const GccFlowStepNo = {
  ...FlowStepNo,
  Interview: 210, // 面接
  PersonnelVerification: 240, // 人事確認
  QualifiedDecision: 250, // 合格者決定
  Offer: 260, // オファー面談
  BeforeAgreed: 500, // 本人確認
  BeforeAgreedSecondment: 510, // 本人確認(出向)
  DoneSecondment: 1010, // 内定承諾(出向)
  OfferDeclined: 1020, // 内定辞退
} as const
export type GccFlowStepNo = typeof GccFlowStepNo[keyof typeof GccFlowStepNo]

export const IccFlowStepNo = {
  ...FlowStepNo,
  PaperScreening: 200, // 書類選考
  Interview: 210, // 面接
  Interview2: 220, // 面接2
  Interview3: 230, // 面接3
  PersonnelVerification: 240, // 人事確認
  QualifiedDecision: 250, // 合格者決定
} as const
export type IccFlowStepNo = typeof IccFlowStepNo[keyof typeof IccFlowStepNo]

export const InterviewResultUpdateType = {
  TempStore: 0,
  Evaluate: 1,
  Modify: 2,
} as const

export type InterviewResultUpdateType = typeof InterviewResultUpdateType[keyof typeof InterviewResultUpdateType]

export const EntryDeclineReasonCategory = {
  IncumbentStay: 1,
  Other: 2,
  Unknown: 3,
} as const
export type EntryDeclineReasonCategory = typeof EntryDeclineReasonCategory[keyof typeof EntryDeclineReasonCategory]

export const EntryDeclineReasonCategoryName: { [key: number]: string } = {
  [EntryDeclineReasonCategory.IncumbentStay]: '現職ステイ',
  [EntryDeclineReasonCategory.Other]: 'その他',
  [EntryDeclineReasonCategory.Unknown]: '不明',
}

export const ExportOptionId = {
  GccEntries: 1,
  CcEntriesAndResumeFiles: 2,
  IccEntries: 3,
  JtEntries: 4,
  JtKickoffFiles: 5,
  JtReflectionFiles: 6,
  WorkshopWorksheets: 7,
  DwEntries: 8,
  DwEntriesAndResumeFiles: 9,
  GcsEntries: 10,
  CsEntriesAndResumeFiles: 11,
  IcsEntries: 12,
} as const
export type ExportOptionId = typeof ExportOptionId[keyof typeof ExportOptionId]

export const ExportOptionName: { [key in ExportOptionId | '']: string } = {
  [ExportOptionId.GccEntries]: 'エントリー情報',
  [ExportOptionId.CcEntriesAndResumeFiles]: 'エントリー情報 & 職務経歴書',
  [ExportOptionId.IccEntries]: 'エントリー情報',
  [ExportOptionId.JtEntries]: 'エントリー情報',
  [ExportOptionId.JtKickoffFiles]: '目標シート',
  [ExportOptionId.JtReflectionFiles]: '振り返りシート',
  [ExportOptionId.WorkshopWorksheets]: 'ワークシート',
  [ExportOptionId.DwEntries]: 'エントリー情報',
  [ExportOptionId.DwEntriesAndResumeFiles]: 'エントリー情報 & 添付ファイル',
  [ExportOptionId.GcsEntries]: 'エントリー情報',
  [ExportOptionId.CsEntriesAndResumeFiles]: 'エントリー情報 & 添付ファイル',
  [ExportOptionId.IcsEntries]: 'エントリー情報',
  '': '',
}

export const EntryActionCategory = {
  FlowStepChange: 1,
  InterviewStatusChange: 2,
  InterviewerAssign: 3,
  InterviewerWithdraw: 4,
  InterviewResultChange: 5,
  SuggestPosition: 6,
  IsSupportRequiredChange: 7,
  remarkChange: 8,
} as const
export type EntryActionCategory = typeof EntryActionCategory[keyof typeof EntryActionCategory]

export const EntryActionCategoryName: { [key in EntryActionCategory | '']: string } = {
  [EntryActionCategory.FlowStepChange]: '選考ステップの変更',
  [EntryActionCategory.InterviewStatusChange]: '面接ステータスの変更',
  [EntryActionCategory.InterviewerAssign]: '面接担当者のアサイン',
  [EntryActionCategory.InterviewerWithdraw]: '面接担当者の解除',
  [EntryActionCategory.InterviewResultChange]: '面接結果の更新',
  [EntryActionCategory.SuggestPosition]: 'ポジション打診',
  [EntryActionCategory.IsSupportRequiredChange]: '要対応フラグの変更',
  [EntryActionCategory.remarkChange]: 'エントリー詳細-備考の変更',
  '': '',
}

export const CursorBasePaginationSortType = {
  IdDesc: 0,
  Random: 2,
} as const
export type CursorBasePaginationSortType = typeof CursorBasePaginationSortType[keyof typeof CursorBasePaginationSortType]

export const UserResumeCursorBasePaginationSortType = {
  ...CursorBasePaginationSortType,
  UrOrgAsc: 3, // 会社昇順
  UrOrgDesc: 4, // 会社降順
  UrYearAsc: 5, // 卒業経過年昇順
  UrYearDesc: 6, // 卒業経過年降順
  UrNameAsc: 7, // 候補者名昇順
  UrNameDesc: 8, // 候補者名降順
} as const
export type UserResumeCursorBasePaginationSortType = typeof UserResumeCursorBasePaginationSortType[keyof typeof UserResumeCursorBasePaginationSortType]

export const InformationStatus = {
  TemporarilySaved: 1,
  Posting: 2,
  PostingSuspended: 3,
} as const
export type InformationStatus = typeof InformationStatus[keyof typeof InformationStatus]

export const ElectionPriority = {
  First: 1,
  Second: 2,
  Third: 3,
} as const
export type ElectionPriority = typeof ElectionPriority[keyof typeof ElectionPriority]

export const ElectionPriorityName: { [key: string]: string } = {
  [ElectionPriority.First]: '第1希望',
  [ElectionPriority.Second]: '第2希望',
  [ElectionPriority.Third]: '第3希望',
}

export const ElectionStatus = {
  Elected: 1,
  Excluded: 2,
  UnEvaluated: 3,
  Rejected: 4,
  Declined: 6,
  RequirementsRejected: 7,
} as const
export type ElectionStatus = typeof ElectionStatus[keyof typeof ElectionStatus]

export const ElectionStatusName: { [key: string]: string } = {
  [ElectionStatus.Elected]: '選出',
  [ElectionStatus.Excluded]: '選外',
  [ElectionStatus.UnEvaluated]: '未選出',
  [ElectionStatus.RequirementsRejected]: '要件不可',
  [ElectionStatus.Rejected]: '落選',
  [ElectionStatus.Declined]: '辞退',
}

export const EntryElectStatusOption = {
  Any: 0,
  First: 1,
  Second: 2,
  Third: 3,
  UnElected: 4,
} as const
export type EntryElectStatusOption = typeof EntryElectStatusOption[keyof typeof EntryElectStatusOption]

export const EntryElectStatusOptionName: { [key: string]: string } = {
  [EntryElectStatusOption.Any]: '選出(すべて)',
  [EntryElectStatusOption.First]: '選出(第1希望)',
  [EntryElectStatusOption.Second]: '選出(第2希望)',
  [EntryElectStatusOption.Third]: '選出(第3希望)',
  [EntryElectStatusOption.UnElected]: '非選出',
}

export const ElectionRemnantOption = {
  Exist: 1,
  UnExist: 2,
  Lack: 3,
  Adequate: 4,
} as const
export type ElectionRemnantOption = typeof ElectionRemnantOption[keyof typeof ElectionRemnantOption]

export const ElectionRemnantOptionName: { [key: string]: string } = {
  [ElectionRemnantOption.Exist]: 'あり',
  [ElectionRemnantOption.UnExist]: 'なし',
  [ElectionRemnantOption.Lack]: '不足(未選出エントリー数未満)',
  [ElectionRemnantOption.Adequate]: '充足(未選出エントリー数以上)',
}

export const ElectionUserElectedPosition = {
  Exist: 1,
  UnExist: 2,
} as const
export type ElectionUserElectedPosition = typeof ElectionUserElectedPosition[keyof typeof ElectionUserElectedPosition]

export const ElectionUserElectedPositionName: { [key: string]: string } = {
  [ElectionUserElectedPosition.Exist]: '選出あり',
  [ElectionUserElectedPosition.UnExist]: '選出なし',
}

export const ElectionReAdjustmentOption = {
  Necessary: 1,
  UnNecessary: 2,
} as const
export type ElectionReAdjustmentOption = typeof ElectionReAdjustmentOption[keyof typeof ElectionReAdjustmentOption]

export const ElectionReAdjustmentOptionName: { [key: string]: string } = {
  [ElectionReAdjustmentOption.Necessary]: '必要',
  [ElectionReAdjustmentOption.UnNecessary]: '不要',
}

export const WorkshopEntryStatus = {
  Ready: 1,
  Done: 2,
  Cancel: 3,
}

export type WorkshopEntryStatus = typeof WorkshopEntryStatus[keyof typeof WorkshopEntryStatus]

export const WorkshopEntryStatusName: { [key: string]: string } = {
  [WorkshopEntryStatus.Ready]: '受講予定',
  [WorkshopEntryStatus.Done]: '受講済',
  [WorkshopEntryStatus.Cancel]: 'キャンセル',
}

export const IsWatchedVideo = {
  NotYet: 0,
  Done: 1,
}
export type IsWatchedVideo = typeof IsWatchedVideo[keyof typeof IsWatchedVideo]

export const IsWatchedVideoName: { [key: string]: string } = {
  [IsWatchedVideo.NotYet]: '未',
  [IsWatchedVideo.Done]: '済',
}

export const EmailVerificationType = {
  CcMail: 1,
  LoginEmail: 2,
} as const
export type EmailVerificationType = typeof EmailVerificationType[keyof typeof EmailVerificationType]

export const ApproveStatus = {
  Approve: 1, // 承認
  Deny: 2, // 否認
  SendBack: 3, // 差し戻し
} as const
export type ApproveStatus = typeof ApproveStatus[keyof typeof ApproveStatus]

export const e2eApiEndpoint = 'https://api.e2e.careermill.epdndo.com/graphql'
export const testApiEndpoint = 'https://api.test.careermill.epdndo.com/graphql'
export const stagingApiEndpoint = 'https://api.staging.careermill.epdndo.com/graphql'
export const localApiEndpoint = 'http://localhost:10002/graphql'

export const LifeLineChartV1DefaultValues: LifeLineChartV1 = {
  version: 1,
  chartData: [
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
  ],
  turningPoints: [
    {
      width: 1152,
      title: '',
      startAt: null,
      endAt: null,
      doing: '',
      point: '',
      interest: '',
      challenge: '',
    },
  ],
  high: '',
  low: '',
  meaning: '',
  interest: '',
  challenge: '',
  whatIsWork: '',
  whatIsWorkReason: '',
  rewardingWork: '',
  rewardingWorkReason: '',
  whatReconfirmed: '',
  thingsNonNegotiable: '',
}

export const LifeLineChartV2DefaultValues: LifeLineChartV2 = {
  version: 2,
  chartData: [
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
    {
      score: 0,
      comment: '',
    },
  ],
  turningPoints: [
    {
      width: 1152,
      title: '',
      startAt: null,
      endAt: null,
      doing: '',
      point: '',
      interest: '',
      challenge: '',
    },
  ],
  valuesAndMotivation: '',
  awareness: '',
}

export const MaxChartDataLength = 20
export const MaxTurningPointLength = 9
export const MinTurningPointWidth = 100

export const ValueOptions = {
  A1: { typeId: 'A', name: '原則', page: 1 },
  A2: { typeId: 'A', name: '使命感', page: 2 },
  A3: { typeId: 'A', name: '社会性', page: 3 },
  A4: { typeId: 'A', name: '意義', page: 4 },
  A5: { typeId: 'A', name: '信念', page: 5 },
  A6: { typeId: 'A', name: '真面目', page: 6 },
  A7: { typeId: 'A', name: '公正', page: 7 },
  B1: { typeId: 'B', name: '金に執着', page: 1 },
  B2: { typeId: 'B', name: '売上', page: 2 },
  B3: { typeId: 'B', name: '利益', page: 3 },
  B4: { typeId: 'B', name: '稼ぐ', page: 4 },
  B5: { typeId: 'B', name: '儲け', page: 5 },
  B6: { typeId: 'B', name: '数字的', page: 6 },
  B7: { typeId: 'B', name: '高収入', page: 7 },
  C1: { typeId: 'C', name: '出世', page: 1 },
  C2: { typeId: 'C', name: '肩書き', page: 2 },
  C3: { typeId: 'C', name: '有名', page: 3 },
  C4: { typeId: 'C', name: '流行', page: 4 },
  C5: { typeId: 'C', name: 'ブランド', page: 5 },
  C6: { typeId: 'C', name: '知名度', page: 6 },
  C7: { typeId: 'C', name: '権威', page: 7 },
  D1: { typeId: 'D', name: '思いやり', page: 1 },
  D2: { typeId: 'D', name: '親切', page: 2 },
  D3: { typeId: 'D', name: '愛情', page: 3 },
  D4: { typeId: 'D', name: '共感', page: 4 },
  D5: { typeId: 'D', name: '尽くす', page: 5 },
  D6: { typeId: 'D', name: '優しさ', page: 6 },
  D7: { typeId: 'D', name: '受容', page: 7 },
  E1: { typeId: 'E', name: '協調', page: 1 },
  E2: { typeId: 'E', name: '仲間愛', page: 2 },
  E3: { typeId: 'E', name: '共有', page: 3 },
  E4: { typeId: 'E', name: 'バランス', page: 4 },
  E5: { typeId: 'E', name: '利他', page: 5 },
  E6: { typeId: 'E', name: 'チームワーク', page: 6 },
  E7: { typeId: 'E', name: '集団主義', page: 7 },
  F1: { typeId: 'F', name: '創造性', page: 1 },
  F2: { typeId: 'F', name: '完成', page: 2 },
  F3: { typeId: 'F', name: 'ユニーク', page: 3 },
  F4: { typeId: 'F', name: '個性的', page: 4 },
  F5: { typeId: 'F', name: 'ひらめき', page: 5 },
  F6: { typeId: 'F', name: '美意識', page: 6 },
  F7: { typeId: 'F', name: '感動', page: 7 },
  G1: { typeId: 'G', name: '忠実', page: 1 },
  G2: { typeId: 'G', name: '平穏', page: 2 },
  G3: { typeId: 'G', name: '温和', page: 3 },
  G4: { typeId: 'G', name: '持続性', page: 4 },
  G5: { typeId: 'G', name: '静かな', page: 5 },
  G6: { typeId: 'G', name: '機械的', page: 6 },
  G7: { typeId: 'G', name: '無変化', page: 7 },
  H1: { typeId: 'H', name: '躍動', page: 1 },
  H2: { typeId: 'H', name: '身体的', page: 2 },
  H3: { typeId: 'H', name: '活動', page: 3 },
  H4: { typeId: 'H', name: '汗をかく', page: 4 },
  H5: { typeId: 'H', name: '運動', page: 5 },
  H6: { typeId: 'H', name: '体力', page: 6 },
  H7: { typeId: 'H', name: '瞬発力', page: 7 },
  I1: { typeId: 'I', name: '安全', page: 1 },
  I2: { typeId: 'I', name: '忠誠', page: 2 },
  I3: { typeId: 'I', name: '忍耐', page: 3 },
  I4: { typeId: 'I', name: '安定', page: 4 },
  I5: { typeId: 'I', name: '誠実', page: 5 },
  I6: { typeId: 'I', name: '調和', page: 6 },
  I7: { typeId: 'I', name: '融和', page: 7 },
  J1: { typeId: 'J', name: '名声', page: 1 },
  J2: { typeId: 'J', name: '実績', page: 2 },
  J3: { typeId: 'J', name: '目標', page: 3 },
  J4: { typeId: 'J', name: '達成', page: 4 },
  J5: { typeId: 'J', name: '高評価', page: 5 },
  J6: { typeId: 'J', name: '賞賛', page: 6 },
  J7: { typeId: 'J', name: '結果', page: 7 },
  K1: { typeId: 'K', name: 'リーダーシップ', page: 1 },
  K2: { typeId: 'K', name: '指導', page: 2 },
  K3: { typeId: 'K', name: 'ビジョン', page: 3 },
  K4: { typeId: 'K', name: 'カリスマ', page: 4 },
  K5: { typeId: 'K', name: '親分肌', page: 5 },
  K6: { typeId: 'K', name: '権力', page: 6 },
  K7: { typeId: 'K', name: '主張', page: 7 },
  L1: { typeId: 'L', name: '自由', page: 1 },
  L2: { typeId: 'L', name: '自己決定', page: 2 },
  L3: { typeId: 'L', name: '自己責任', page: 3 },
  L4: { typeId: 'L', name: '自己采配', page: 4 },
  L5: { typeId: 'L', name: '独立', page: 5 },
  L6: { typeId: 'L', name: '異端', page: 6 },
  L7: { typeId: 'L', name: '自律', page: 7 },
  M1: { typeId: 'M', name: '挑戦', page: 1 },
  M2: { typeId: 'M', name: 'バイタリティ', page: 2 },
  M3: { typeId: 'M', name: 'リスク', page: 3 },
  M4: { typeId: 'M', name: '野心', page: 4 },
  M5: { typeId: 'M', name: '冒険', page: 5 },
  M6: { typeId: 'M', name: '不屈', page: 6 },
  M7: { typeId: 'M', name: '勝負', page: 7 },
  N1: { typeId: 'N', name: '分析', page: 1 },
  N2: { typeId: 'N', name: '発見', page: 2 },
  N3: { typeId: 'N', name: '観察', page: 3 },
  N4: { typeId: 'N', name: '研究', page: 4 },
  N5: { typeId: 'N', name: '概念', page: 5 },
  N6: { typeId: 'N', name: '知性', page: 6 },
  N7: { typeId: 'N', name: '客観', page: 7 },
  O1: { typeId: 'O', name: 'ワークライフバランス', page: 1 },
  O2: { typeId: 'O', name: '休暇取得', page: 2 },
  O3: { typeId: 'O', name: '家族主義', page: 3 },
  O4: { typeId: 'O', name: '余暇', page: 4 },
  O5: { typeId: 'O', name: '趣味命', page: 5 },
  O6: { typeId: 'O', name: 'プライベート重視', page: 6 },
  O7: { typeId: 'O', name: '休暇満喫', page: 7 },
} as const
export type ValueOptionKeys = keyof typeof ValueOptions
export type ValueOptions = typeof ValueOptions[ValueOptionKeys]

export const ValueTypes = {
  A: '社会性重視型',
  B: '経済的報酬型',
  C: '肩書き重視型',
  D: '他者への支援型',
  E: 'チームワーク重視型',
  F: '美的創造性発揮型',
  G: 'ルーティンワーク型',
  H: '身体的活動型',
  I: '安定型',
  J: '社会的評価型',
  K: 'リーダーシップ型',
  L: '自律型',
  M: '挑戦型',
  N: '調査・研究型',
  O: 'プライベート重視型',
} as const
export type ValueTypeKeys = keyof typeof ValueTypes
export type ValueTypes = typeof ValueTypes[ValueTypeKeys]

export const ValueCheckV1DefaultValues: ValueCheckV1 = {
  version: 1,
  values: [],
  valueTypes: [
    {
      id: 'A',
      comment: '',
    },
    {
      id: 'B',
      comment: '',
    },
    {
      id: 'C',
      comment: '',
    },
    {
      id: 'D',
      comment: '',
    },
    {
      id: 'E',
      comment: '',
    },
    {
      id: 'F',
      comment: '',
    },
    {
      id: 'G',
      comment: '',
    },
    {
      id: 'H',
      comment: '',
    },
    {
      id: 'I',
      comment: '',
    },
    {
      id: 'J',
      comment: '',
    },
    {
      id: 'K',
      comment: '',
    },
    {
      id: 'L',
      comment: '',
    },
    {
      id: 'M',
      comment: '',
    },
    {
      id: 'N',
      comment: '',
    },
    {
      id: 'O',
      comment: '',
    },
  ],
}

export const LookingBackOnGrowthV1DefaultValues: LookingBackOnGrowthV1 = {
  version: 1,
  definition: '',
  comparison: '',
  factorsOfChanges: [
    {
      contents: '',
      category: '',
    },
    {
      contents: '',
      category: '',
    },
    {
      contents: '',
      category: '',
    },
    {
      contents: '',
      category: '',
    },
  ],
  forTheFuture: '',
}

export const InventoryOfOwnResourcesV1DefaultValues: InventoryOfOwnResourcesV1 = {
  version: 1,
  technicalSkills: {
    strengths: '',
    weakness: '',
  },
  portableSkills: {
    strengths: '',
    weakness: '',
  },
  stance: {
    strengths: '',
    weakness: '',
  },
}

export const NextStepV1DefaultValues: NextStepV1 = {
  version: 1,
  futureGoals: {
    appearanceThreeYearsLater: '',
    organizationalVision: '',
    currentBusinessMission: '',
    whatIsExpectedOfYou: '',
    whatYouCanLearnThroughYourCurrentWork: '',
    whatYouWantToLearnBy3Years: '',
  },
  actionPlan: [
    { stateGoal: '', byWhen: '', actionPlan: '' },
    { stateGoal: '', byWhen: '', actionPlan: '' },
    { stateGoal: '', byWhen: '', actionPlan: '' },
  ],
}

export const NextStepV2DefaultValues: NextStepV2 = {
  version: 2,
  futureGoals: {
    years: '',
    appearanceYearsLater: '',
    organizationalVision: '',
    currentBusinessMission: '',
    whatIsExpectedOfYou: '',
    whatYouCanLearnThroughYourCurrentWork: '',
    whatYouWantToLearn: '',
  },
  actionPlan: [
    { stateGoal: '', byWhen: '', actionPlan: '' },
    { stateGoal: '', byWhen: '', actionPlan: '' },
    { stateGoal: '', byWhen: '', actionPlan: '' },
  ],
}

export const VectorAndVisionV1DefaultValues: VectorAndVisionV1 = {
  version: 1,
  imageOfLife: '',
  positioningOfWork: '',
  wantToBePrivateLife: '',
  wantToBeWorker: '',
  wantToBeAndWantToDo: '',
  whatAndWhoProvideToValue: '',
  whatToLearn: '',
}

export const VectorAndVisionV2DefaultValues: VectorAndVisionV2 = {
  version: 2,
  imageOfLife: '',
  positioningOfWork: '',
  wantToBePrivateLife: '',
  wantToBeWorker: '',
  whatToLearn: '',
}

export const TagType = {
  Position: 0,
  Doublework: 1,
} as const
export type TagType = typeof TagType[keyof typeof TagType]

export const DoubleworkPlaceholderSample = {
  Lecturer: 1,
  DigitalSkill: 2,
  Creative: 3,
} as const
export type DoubleworkPlaceholderSample = typeof DoubleworkPlaceholderSample[keyof typeof DoubleworkPlaceholderSample]

export const DoubleworkPlaceholderSampleName: { [key: string]: string } = {
  [DoubleworkPlaceholderSample.Lecturer]: '講師系',
  [DoubleworkPlaceholderSample.DigitalSkill]: 'デジタルスキル系',
  [DoubleworkPlaceholderSample.Creative]: 'クリエイティブ系',
}

export const WorkingLocationType = {
  Remote: 1,
  FaceToFace: 2,
  RemoteAndFaceToFace: 3,
} as const
export type WorkingLocationType = typeof WorkingLocationType[keyof typeof WorkingLocationType]

export const WorkingLocationTypeName: { [key: string]: string } = {
  [WorkingLocationType.Remote]: 'リモート',
  [WorkingLocationType.FaceToFace]: '対面',
  [WorkingLocationType.RemoteAndFaceToFace]: 'リモート＋対面',
}

export const RewardAmountType = {
  NotSelected: 0,
  PersonMonthAmount: 1,
  MonthlyAmount: 2,
} as const
export type RewardAmountType = typeof RewardAmountType[keyof typeof RewardAmountType]

export const RewardAmountTypeName: { [key: string]: string } = {
  [RewardAmountType.PersonMonthAmount]: '人月',
  [RewardAmountType.MonthlyAmount]: '月額',
}

export const Difficulty = {
  NotSelected: 0,
  ElementaryAndIntermediate: 1,
  IntermediateAndAbove: 2,
} as const
export type Difficulty = typeof Difficulty[keyof typeof Difficulty]

export const DifficultyName: { [key: string]: string } = {
  [Difficulty.ElementaryAndIntermediate]: '初中級',
  [Difficulty.IntermediateAndAbove]: '中級以上',
}

export const DoubleworkStatus = {
  TemporarilySaved: 1,
  BeforeApproval: 2,
  AfterApproval: 3,
  PostingSuspended: 4,
} as const
export type DoubleworkStatus = typeof DoubleworkStatus[keyof typeof DoubleworkStatus]

export const DoubleworkEntryUserPermission = {
  EntryUser: 1, // エントリーユーザー
  AccountAdmin: 2, // 複業グループ人事
  UsersAdmin: 3, // 輩出元個社人事
  PublisherAdmin: 4, // 発注元個社人事
  Trainer: 5, // 案件受入責任者
  ResponsibleUser: 6, // 案件担当者
  Selector: 7, // 選考担当者
}
export type DoubleworkEntryUserPermission = typeof DoubleworkEntryUserPermission[keyof typeof DoubleworkEntryUserPermission]

export const DoubleworkFlowStepNo = {
  Unapproved: 0, // 未承認
  Approved: 200, // 承認済み
  Consideration: 210, // 検討中
  Decision: 500, // 内定
  ContractPreparation: 510, // 契約準備
  ContractSigned: 1000, // 契約中
  ContractConfirmation: 1010, // 契約状況確認
  ContractContinuationUnapproved: 1020, // 契約継続未確認
  ContractTerminationPreparation: 1030, // 契約終了準備
  Rejected: 1500, // 見送り
  Declined: 1510, // 辞退
  ContractTermination: 1520, // 契約終了
  Denied: 1530, // 否認
} as const
export type DoubleworkFlowStepNo = typeof DoubleworkFlowStepNo[keyof typeof DoubleworkFlowStepNo]

export interface DoubleworkEntryFlowStep {
  no: DoubleworkFlowStepNo
  name: string
  candidateVisibleName: string
}

export const DoubleworkEntryFlowConstants: { steps: DoubleworkEntryFlowStep[] } = {
  steps: [
    {
      no: DoubleworkFlowStepNo.Unapproved,
      name: '未承認',
      candidateVisibleName: '未承認',
    },
    {
      no: DoubleworkFlowStepNo.Approved,
      name: '承認済',
      candidateVisibleName: '承認済',
    },
    {
      no: DoubleworkFlowStepNo.Consideration,
      name: '検討中',
      candidateVisibleName: '検討中',
    },
    {
      no: DoubleworkFlowStepNo.Decision,
      name: '内定',
      candidateVisibleName: '内定',
    },
    {
      no: DoubleworkFlowStepNo.ContractPreparation,
      name: '契約準備',
      candidateVisibleName: '契約準備',
    },
    {
      no: DoubleworkFlowStepNo.ContractSigned,
      name: '契約中',
      candidateVisibleName: '契約中',
    },
    {
      no: DoubleworkFlowStepNo.ContractConfirmation,
      name: '契約状況確認',
      candidateVisibleName: '契約状況確認',
    },
    {
      no: DoubleworkFlowStepNo.ContractContinuationUnapproved,
      name: '契約継続未確認',
      candidateVisibleName: '契約継続未確認',
    },
    {
      no: DoubleworkFlowStepNo.ContractTerminationPreparation,
      name: '契約終了準備',
      candidateVisibleName: '契約終了準備',
    },
    {
      no: DoubleworkFlowStepNo.Denied,
      name: '否認',
      candidateVisibleName: '否認',
    },
    {
      no: DoubleworkFlowStepNo.Rejected,
      name: '見送り',
      candidateVisibleName: '見送り',
    },
    {
      no: DoubleworkFlowStepNo.Declined,
      name: '辞退',
      candidateVisibleName: '辞退',
    },
    {
      no: DoubleworkFlowStepNo.ContractTermination,
      name: '契約終了',
      candidateVisibleName: '契約終了',
    },
  ],
}

export const DoubleworkEntryActionCategory = {
  FlowStepChange: 1,
  SelectorAssign: 2,
  SelectorWithdraw: 3,
  ProceduresChange: 4,
  IsDecidedChange: 5,
} as const
export type DoubleworkEntryActionCategory = typeof DoubleworkEntryActionCategory[keyof typeof DoubleworkEntryActionCategory]

export const DoubleworkEntryActionCategoryName: { [key in DoubleworkEntryActionCategory | '']: string } = {
  [DoubleworkEntryActionCategory.FlowStepChange]: '選考ステップの変更',
  [DoubleworkEntryActionCategory.SelectorAssign]: '選考担当者のアサイン',
  [DoubleworkEntryActionCategory.SelectorWithdraw]: '選考担当者の解除',
  [DoubleworkEntryActionCategory.ProceduresChange]: '手続き詳細のステータス',
  [DoubleworkEntryActionCategory.IsDecidedChange]: '候補者決定フラグの変更',
  '': '',
}

export const DoubleworkEntryDeclineReasonCategory = {
  SkillMismatch: 1,
  BusyBusiness: 2,
  Other: 3,
} as const
export type DoubleworkEntryDeclineReasonCategory = typeof DoubleworkEntryDeclineReasonCategory[keyof typeof DoubleworkEntryDeclineReasonCategory]

export const DoubleworkEntryDeclineReasonCategoryName: { [key in DoubleworkEntryDeclineReasonCategory]: string } = {
  [DoubleworkEntryDeclineReasonCategory.SkillMismatch]: 'スキルのミスマッチ',
  [DoubleworkEntryDeclineReasonCategory.BusyBusiness]: '業務の繁忙',
  [DoubleworkEntryDeclineReasonCategory.Other]: 'その他',
}

export const MaxDmMessageLength = 6000

export const ConsiderationType = {
  ShortenedWorkingHours: 1,
  Others: 2,
  None: 3,
} as const
export type ConsiderationType = typeof ConsiderationType[keyof typeof ConsiderationType]

export const ConsiderationTypeName: { [key: string]: string } = {
  [ConsiderationType.ShortenedWorkingHours]: '時短',
  [ConsiderationType.Others]: 'その他(自由入力)',
  [ConsiderationType.None]: 'なし',
}

export const WorkshopSurveyV1Detail = {
  thinkAboutCareer: {
    question: 'あなたは、日頃からご自身のキャリアについて考えていますか？',
    options: ['よく考えている', 'たまに考えている', 'あまり考えていない', 'まったく考えていない'],
    required: true,
  },
  verbalizeCareer: {
    question: `Q1で「よく考えている」「たまに考えている」を選択された方のみご回答下さい：
ご自身のキャリア(むこう3年以上位)について言語化はできていますか？`,
    options: ['詳細に言語化できている', 'おおむね言語化できている', '言語化はできていない'],
    required: true,
  },
  whyNotVerbalizeCareer: {
    question: `Q1で「あまり考えていない」「まったく考えていない」を選択された方、またはQ2で「言語化はできていない」を選択された方のみご回答下さい：
自身のキャリアについて考えられていない、また、言語化できていないのはなぜですか？ その理由をご入力ください。`,
    options: ['時間がない', 'やり方がわからない', '必要性を感じない', 'その他(自由入力)'],
    required: true,
  },
  whyNotVerbalizeCareerOthers: {
    placeholder: 'その他の理由',
    required: true,
  },
  reason: {
    question: 'キャリアデザインワークショップへの参加を希望されている理由をご入力下さい。',
    options: [
      '現状に不安やもやもやがあるため、自身のキャリアを見つめなおしたい',
      'キャリアについての考え方やキャリアデザインのやり方が知りたい',
      '自身のキャリアデザインをしたい(言語化したい)',
      '言語化できつつあるキャリアについて、より具体的な行動にうつすヒントが欲しい',
      '自身が選択してきたキャリアについて改めて振り返りたい',
      'キャリア開発・キャリアデザイン研修に興味があるため',
      'グループ間交流',
      'その他(自由入力)',
    ],
    required: true,
  },
  reasonOthers: {
    placeholder: 'その他の理由',
    required: true,
  },
}

export const WorkshopSurveyV2Detail = {
  thinkAboutCareer: {
    question: 'あなたは、日頃からご自身のキャリアについて考えていますか？',
    options: ['よく考えている', 'たまに考えている', 'あまり考えていない', 'まったく考えていない'],
    required: true,
  },
  verbalizeCareer: {
    question: `Q1で「よく考えている」「たまに考えている」を選択された方のみご回答下さい：
ご自身のキャリア(むこう3年以上位)について言語化はできていますか？`,
    options: ['詳細に言語化できている', 'おおむね言語化できている', '言語化はできていない'],
    required: true,
  },
  whyNotVerbalizeCareer: {
    question: `Q1で「あまり考えていない」「まったく考えていない」を選択された方、またはQ2で「言語化はできていない」を選択された方のみご回答下さい：
自身のキャリアについて考えられていない、また、言語化できていないのはなぜですか？ その理由をご入力ください。`,
    options: ['時間がない', 'やり方がわからない', '必要性を感じない', 'その他(自由入力)'],
    required: true,
  },
  whyNotVerbalizeCareerOthers: {
    placeholder: 'その他の理由',
    required: true,
  },
  howManySubordinatesThinkAboutCareer: {
    question: '現在の部下の方(GP職)のうち何割くらいの方が、日頃から自身のキャリアについて考えていると感じていますか？',
    options: ['90％以上', '70～90％位', '50～70％位', '30～50％位', '30％以下'],
    required: true,
  },
  difficultCareerSupportCases: {
    question:
      '部下のキャリア支援を行う上で、対応に困るケースがあればご入力下さい。(研修の中でケースを考える際の参考にさせていただきます。)',
    required: false,
  },
}

export const DoubleworkProcedures: { [key: string]: number } = {
  ConcludeContract: 1,
  Preparation: 2,
  Infrastructure: 3,
  PaymentAndBilling: 4,
  TaxReturn: 5,
  TerminateContract: 6,
}
export type DoubleworkProcedures = typeof DoubleworkProcedures[keyof typeof DoubleworkProcedures]

export const DoubleworkProceduresName: { [key: number]: string } = {
  [DoubleworkProcedures.ConcludeContract]: '契約締結',
  [DoubleworkProcedures.Preparation]: '複業準備',
  [DoubleworkProcedures.Infrastructure]: 'PC・ITインフラ整備',
  [DoubleworkProcedures.PaymentAndBilling]: '支払・請求',
  [DoubleworkProcedures.TaxReturn]: '確定申告',
  [DoubleworkProcedures.TerminateContract]: '複業終了',
}

export const DoubleworkProceduresConcludeContract = {
  ApplicationForOutsourcing: 1,
  ContractDrafting: 2,
  PurchaseApplication: 3,
  StampingContract: 4,
}
export type DoubleworkProceduresConcludeContract = typeof DoubleworkProceduresConcludeContract[keyof typeof DoubleworkProceduresConcludeContract]

export const DoubleworkProceduresPreparation = {
  ApplicationForDoublework: 1,
  AccountIssue: 2,
}
export type DoubleworkProceduresPreparation = typeof DoubleworkProceduresPreparation[keyof typeof DoubleworkProceduresPreparation]

export const DoubleworkProceduresInfrastructure = {
  LoanArrangementApplication: 1,
  VDIApplication: 2,
  PulseSecureApplication: 3,
}
export type DoubleworkProceduresInfrastructure = typeof DoubleworkProceduresInfrastructure[keyof typeof DoubleworkProceduresInfrastructure]

export const DoubleworkProceduresPaymentAndBilling = {
  RIBBONRegistrationApplicationForm: 1,
  RIBBONRegistration: 2,
  Invoicing: 3,
  CommissionPayment: 4,
}
export type DoubleworkProceduresPaymentAndBilling = typeof DoubleworkProceduresPaymentAndBilling[keyof typeof DoubleworkProceduresPaymentAndBilling]

export const DoubleworkProceduresTaxReturn = {
  TaxReturn: 1,
}
export type DoubleworkProceduresTaxReturn = typeof DoubleworkProceduresTaxReturn[keyof typeof DoubleworkProceduresTaxReturn]

export const DoubleworkProceduresTerminateContract = {
  TerminateContractApplication: 1,
  AccountCancellation: 2,
}
export type DoubleworkProceduresTerminateContract = typeof DoubleworkProceduresTerminateContract[keyof typeof DoubleworkProceduresTerminateContract]

export const DoubleworkProceduresSteps: { [key: number]: { [key: number]: string } } = {
  [DoubleworkProcedures.ConcludeContract]: {
    [DoubleworkProceduresConcludeContract.ApplicationForOutsourcing]: '外部委託申請',
    [DoubleworkProceduresConcludeContract.ContractDrafting]: '契約書の作成/法務審査',
    [DoubleworkProceduresConcludeContract.PurchaseApplication]: '購買申請',
    [DoubleworkProceduresConcludeContract.StampingContract]: '契約書への押印',
  },
  [DoubleworkProcedures.Preparation]: {
    [DoubleworkProceduresPreparation.ApplicationForDoublework]: '複業申請',
    [DoubleworkProceduresPreparation.AccountIssue]: 'アカウント発行',
  },
  [DoubleworkProcedures.Infrastructure]: {
    [DoubleworkProceduresInfrastructure.LoanArrangementApplication]: '貸与物手配申請',
    [DoubleworkProceduresInfrastructure.VDIApplication]: 'VDI申請',
    [DoubleworkProceduresInfrastructure.PulseSecureApplication]: 'PulseSecure(VPN)申請',
  },
  [DoubleworkProcedures.PaymentAndBilling]: {
    [DoubleworkProceduresPaymentAndBilling.RIBBONRegistrationApplicationForm]: 'ROBIN登録申請書の作成',
    [DoubleworkProceduresPaymentAndBilling.RIBBONRegistration]: 'ROBIN登録',
    [DoubleworkProceduresPaymentAndBilling.Invoicing]: '請求書発行',
    [DoubleworkProceduresPaymentAndBilling.CommissionPayment]: '委託料支払い',
  },
  [DoubleworkProcedures.TaxReturn]: {
    [DoubleworkProceduresTaxReturn.TaxReturn]: '確定申告',
  },
  [DoubleworkProcedures.TerminateContract]: {
    [DoubleworkProceduresTerminateContract.TerminateContractApplication]: '複業終了申請',
    [DoubleworkProceduresTerminateContract.AccountCancellation]: 'アカウント解除',
  },
}

export const SurveyType = {
  Post: 1,
  HalfYearLater: 2,
} as const

export type SurveyType = typeof SurveyType[keyof typeof SurveyType]

export const SurveyTypeName: { [key: string]: string } = {
  [SurveyType.Post]: '事後',
  [SurveyType.HalfYearLater]: '半年後',
}

export const InputType = {
  Text: 1,
  Select: 2,
  SelectMultiple: 3,
  Slider: 4,
} as const
export type InputType = typeof InputType[keyof typeof InputType]

export const CareerType = {
  workExperience: 1, // 職歴
  academicBackground: 2, // 学歴
} as const
export type CareerType = typeof CareerType[keyof typeof CareerType]

export const UploadLimit = {
  maxFileSize: 10 * 1024 * 1024, // bytes
  maxFiles: 30,
}

export const defaultAnnualIncome =
  '現在の理論年収をスライドさせる。但し、当社の人事制度に従って理論年収を下回る提示額となる場合がある。'
export const defaultAnnualIncomeForOverseasSegment =
  '現地海外法人の人事制度、給与水準に基づいた提示がされる。\n物価水準等を考慮し、異動時点の国内理論年収を上回る/下回る提示額となる場合がある。'

export const defaultSocialInsurance = '健康保険：有、雇用保険：有、厚生年金：有、労災保険：有、介護保険：有'
export const defaultSocialInsuranceForOverseasSegment =
  '健康保険：無、雇用保険：有、厚生年金：無、労災保険：海外特別加入、介護保険：無\n日本の健康保険が適用されないため、海外現地での医療等に対する福利厚生として、出向期間中は会社負担にて海外旅行傷害保険に加入する。'

export const defaultCSapplicationRequirement = `以下に該当する社員はエントリー対象外とする
・1年以内に入社した新卒・中途社員
・1年以内に出向または転籍した社員 ※1
・現在休職中の社員および休職を予定している社員 ※2
・今後退職を予定している社員
・原則異動日に雇用区分がG職以外に切り替わる予定のある社員
※1 詳細は【出向・転籍における応募要件の考え方】を参照すること
※2 詳細は【休職予定者における応募要件の考え方】を参照すること`

export const CareerScoutLimit = {
  maxPositionPerOrganization: 20, // 個社ごとの半期で承認できるスカウトポジションの数
  maxGroupOfferPerPosition: 10, // スカウトポジション毎のグループスカウトのオファーが送れる最大数
}

// FY24下期のPBD,PXT限定のCareerScoutLimit.maxPositionPerOrganization
export const CareerScoutSpecialLimit: { [externalId: string]: number } = {
  '200033': 40, // PBD
  '200007': 26, // PXT
}

export const CareerScoutStatus = {
  TemporarilySaved: 1,
  BeforeApproval: 2,
  AfterApproval: 3,
  PostingSuspended: 4,
} as const
export type CareerScoutStatus = typeof CareerScoutStatus[keyof typeof CareerScoutStatus]

export const SenderStatus = {
  InPreparation: 1,
  Unopened: 2,
  Opened: 3,
  Entered: 4,
  Closed: 5,
}
export type SenderStatus = typeof SenderStatus[keyof typeof SenderStatus]

export const SenderStatusName: { [key: string]: string } = {
  [SenderStatus.InPreparation]: '作成中',
  [SenderStatus.Unopened]: '未開封',
  [SenderStatus.Opened]: '開封済',
  [SenderStatus.Entered]: 'エントリー済',
  [SenderStatus.Closed]: '終了',
}

export const RecipientStatus = {
  Unopened: 1,
  Opened: 2,
  Entered: 3,
  Closed: 4,
}
export type RecipientStatus = typeof RecipientStatus[keyof typeof RecipientStatus]

export const RecipientStatusName: { [key: string]: string } = {
  [RecipientStatus.Unopened]: '未開封',
  [RecipientStatus.Opened]: '開封済',
  [RecipientStatus.Entered]: 'エントリー済',
  [RecipientStatus.Closed]: '終了',
}

export const ReadStatus = {
  Unread: 1,
  AlreadyRead: 2,
}
export type ReadStatus = typeof ReadStatus[keyof typeof ReadStatus]

export const ReadStatusName: { [key: string]: string } = {
  [ReadStatus.Unread]: '未読',
  [ReadStatus.AlreadyRead]: '既読',
}

export const CareerScoutCommonFlowStepNo = {
  Consideration: 50, // 選考準備
  PaperScreening: 200, // 書類選考
  Interview: 210, // 面接
  Interview2: 220, // 面接2
  Interview3: 230, // 面接3
  QualifiedDecision: 250, // 合格者決定
  Offer: 260, // オファー面談
  BeforeAgreed: 500, // 本人確認
  Done: 1000, // 内定承諾、異動承諾
  OfferDeclined: 1020, // 内定辞退、異動辞退
  Rejected: 1500, // 不合格
  Declined: 1520, // 辞退
  RequirementsRejected: 1530, // 要件不可
} as const
export type CareerScoutCommonFlowStepNo = typeof CareerScoutCommonFlowStepNo[keyof typeof CareerScoutCommonFlowStepNo]

export const CareerScoutGroupFlowStepNo = {
  ...CareerScoutCommonFlowStepNo,
  PersonnelVerification: 240, // 合格者人事確認
  BeforeAgreedSecondment: 510, // 本人確認(出向)
  DoneSecondment: 1010, // 内定承諾(出向)
} as const
export type CareerScoutGroupFlowStepNo = typeof CareerScoutGroupFlowStepNo[keyof typeof CareerScoutGroupFlowStepNo]

export interface CareerScoutGroupEntryFlowStep {
  no: CareerScoutGroupFlowStepNo
  name: string
  candidateVisibleName: string
}

export const CareerScoutIndividualFlowStepNo = {
  ...CareerScoutCommonFlowStepNo,
} as const
export type CareerScoutIndividualFlowStepNo = typeof CareerScoutIndividualFlowStepNo[keyof typeof CareerScoutIndividualFlowStepNo]

export interface CareerScoutIndividualEntryFlowStep {
  no: CareerScoutIndividualFlowStepNo
  name: string
  candidateVisibleName: string
}

export const CareerScoutGroupEntryFlowConstants: { steps: CareerScoutGroupEntryFlowStep[] } = {
  steps: [
    {
      no: CareerScoutGroupFlowStepNo.Consideration,
      name: '選考準備',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.PaperScreening,
      name: '書類選考',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.Interview,
      name: '面接',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.Interview2,
      name: '面接2',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.Interview3,
      name: '面接3',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.PersonnelVerification,
      name: '合格者人事確認',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.QualifiedDecision,
      name: '合格者決定',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.Offer,
      name: 'オファー面談',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutGroupFlowStepNo.BeforeAgreed,
      name: '本人確認',
      candidateVisibleName: '内定',
    },
    {
      no: CareerScoutGroupFlowStepNo.BeforeAgreedSecondment,
      name: '本人確認(出向)',
      candidateVisibleName: '内定',
    },
    {
      no: CareerScoutGroupFlowStepNo.Done,
      name: '内定承諾',
      candidateVisibleName: '内定承諾',
    },
    {
      no: CareerScoutGroupFlowStepNo.DoneSecondment,
      name: '内定承諾(出向)',
      candidateVisibleName: '内定承諾',
    },
    {
      no: CareerScoutGroupFlowStepNo.OfferDeclined,
      name: '内定辞退',
      candidateVisibleName: '内定辞退',
    },
    {
      no: CareerScoutGroupFlowStepNo.RequirementsRejected,
      name: '要件不可',
      candidateVisibleName: '終了',
    },
    {
      no: CareerScoutGroupFlowStepNo.Rejected,
      name: '不合格',
      candidateVisibleName: '終了',
    },
    {
      no: CareerScoutGroupFlowStepNo.Declined,
      name: '辞退',
      candidateVisibleName: '終了',
    },
  ],
}

export const CareerScoutIndividualEntryFlowConstants: { steps: CareerScoutIndividualEntryFlowStep[] } = {
  steps: [
    {
      no: CareerScoutIndividualFlowStepNo.Consideration,
      name: '選考準備',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.PaperScreening,
      name: '書類選考',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Interview,
      name: '面接',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Interview2,
      name: '面接2',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Interview3,
      name: '面接3',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.QualifiedDecision,
      name: '合格者決定',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Offer,
      name: 'オファー面談',
      candidateVisibleName: '選考中',
    },
    {
      no: CareerScoutIndividualFlowStepNo.BeforeAgreed,
      name: '本人確認',
      candidateVisibleName: '内定',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Done,
      name: '異動承諾',
      candidateVisibleName: '内定承諾',
    },
    {
      no: CareerScoutIndividualFlowStepNo.OfferDeclined,
      name: '異動辞退',
      candidateVisibleName: '内定辞退',
    },
    {
      no: CareerScoutIndividualFlowStepNo.RequirementsRejected,
      name: '要件不可',
      candidateVisibleName: '終了',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Rejected,
      name: '不合格',
      candidateVisibleName: '終了',
    },
    {
      no: CareerScoutIndividualFlowStepNo.Declined,
      name: '辞退',
      candidateVisibleName: '終了',
    },
  ],
}

export const CareerScoutEntryActionCategory = {
  FlowStepChange: 1,
  InterviewStatusChange: 2,
  InterviewerAssign: 3,
  InterviewerWithdraw: 4,
  InterviewResultChange: 5,
  IsSupportRequiredChange: 7,
  remarkChange: 8,
} as const
export type CareerScoutEntryActionCategory = typeof CareerScoutEntryActionCategory[keyof typeof CareerScoutEntryActionCategory]

export const CareerScoutEntryActionCategoryName: { [key in CareerScoutEntryActionCategory | '']: string } = {
  [CareerScoutEntryActionCategory.FlowStepChange]: '選考ステップの変更',
  [CareerScoutEntryActionCategory.InterviewStatusChange]: '面接ステータスの変更',
  [CareerScoutEntryActionCategory.InterviewerAssign]: '面接担当者のアサイン',
  [CareerScoutEntryActionCategory.InterviewerWithdraw]: '面接担当者の解除',
  [CareerScoutEntryActionCategory.InterviewResultChange]: '面接結果の更新',
  [CareerScoutEntryActionCategory.IsSupportRequiredChange]: '要対応フラグの変更',
  [CareerScoutEntryActionCategory.remarkChange]: 'エントリー詳細-備考の変更',
  '': '',
}

export const CareerScoutEntryDeclineReasonCategory = {
  SkillMismatch: 1,
  BusyBusiness: 2,
  Other: 3,
} as const
export type CareerScoutEntryDeclineReasonCategory = typeof CareerScoutEntryDeclineReasonCategory[keyof typeof CareerScoutEntryDeclineReasonCategory]

export const CareerScoutEntryDeclineReasonCategoryName: { [key in CareerScoutEntryDeclineReasonCategory]: string } = {
  [CareerScoutEntryDeclineReasonCategory.SkillMismatch]: 'スキルのミスマッチ',
  [CareerScoutEntryDeclineReasonCategory.BusyBusiness]: '業務の繁忙',
  [CareerScoutEntryDeclineReasonCategory.Other]: 'その他',
}

export const CareerScoutEntryUserPermission = {
  EntryUser: 1, // 候補者
  AccountAdmin: 2, // G人事
  Admin: 3, // 個社人事
  ResponsibleManager: 4, // 案件担当、作成者(部長以上)
  Interviewer: 5, // 面接担当者(すべてのエントリーステップ、今期エントリー)
}
export type CareerScoutEntryUserPermission = typeof CareerScoutEntryUserPermission[keyof typeof CareerScoutEntryUserPermission]

export const defaultCCapplicationRequirement = `以下に該当する社員はエントリー対象外とする
・2023年10月2日以降に入社した新卒・中途社員
・2023年10月2日以降に出向・または転籍した社員 ※1
・2024年10月1日以降に最終出社日を迎える退職予定社員
※1 詳細はマニュアル参照`

export const defaultJTapplicationRequirement = `以下に該当する社員はエントリー対象外とする
・2023年10月2日以降に入社した新卒・中途社員
・2023年10月2日以降に出向・または転籍した社員 ※1
・2024年10月1日以降に最終出社日を迎える退職予定社員
※1 詳細はマニュアル参照`

export interface Video {
  fileKey: string
  version: number
  title: string
  thumbnail: string
  completeFraction: number // 動画を視聴完了にする割合(分数)
  totalSeconds: number // 動画の長さ(秒)
}

// 動画とサムネイルを格納する親ディレクトリ
const videoDirectory = 'resources/videos'

// 視聴必須ビデオのfileKey
export const RequiredVideoFileKey = {
  DoubleworkRequired: `${videoDirectory}/doublework/publisher_point_1.mp4`, // 案件作成時
  DoubleworkEntryRequired: `${videoDirectory}/doublework/entryuser_point_1.mp4`, // 複業エントリー時
}

export const videos: Video[] = [
  {
    // 複業案件作成時必須動画
    fileKey: RequiredVideoFileKey.DoubleworkRequired,
    thumbnail: `${videoDirectory}/doublework/publisher_point_1.png`,
    version: 1,
    title: '【グループ内複業】発注元向けクイックスタート動画',
    completeFraction: 0.7,
    totalSeconds: 122,
  },
  {
    // 複業エントリー時必須動画
    fileKey: RequiredVideoFileKey.DoubleworkEntryRequired,
    thumbnail: `${videoDirectory}/doublework/entryuser_point_1.png`,
    version: 1,
    title: '【グループ内複業】複業者向けクイックスタート動画',
    completeFraction: 0.7,
    totalSeconds: 122,
  },
  // 人事より
  {
    fileKey: `${videoDirectory}/doublework/admin_1.mp4`,
    thumbnail: `${videoDirectory}/doublework/admin_1.png`,
    version: 1,
    title: '【グループ内複業】制度概要の解説動画',
    completeFraction: 0.7,
    totalSeconds: 179,
  },
  {
    fileKey: `${videoDirectory}/doublework/admin_2.mp4`,
    thumbnail: `${videoDirectory}/doublework/admin_2.png`,
    version: 1,
    title: '【グループ内複業】制度利用者の声と事例の紹介動画',
    completeFraction: 0.7,
    totalSeconds: 345,
  },
  // 発注元詳細版
  {
    fileKey: `${videoDirectory}/doublework/publisher_1.mp4`,
    thumbnail: `${videoDirectory}/doublework/publisher_1.png`,
    version: 1,
    title: '【グループ内複業】複業者との取引前のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 435,
  },
  {
    fileKey: `${videoDirectory}/doublework/publisher_2.mp4`,
    thumbnail: `${videoDirectory}/doublework/publisher_2.png`,
    version: 1,
    title: '【グループ内複業】複業者との取引中のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 337,
  },
  {
    fileKey: `${videoDirectory}/doublework/publisher_3.mp4`,
    thumbnail: `${videoDirectory}/doublework/publisher_3.png`,
    version: 1,
    title: '【グループ内複業】複業者との取引後のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 181,
  },
  // 複業者詳細版
  {
    fileKey: `${videoDirectory}/doublework/entryuser_1.mp4`,
    thumbnail: `${videoDirectory}/doublework/entryuser_1.png`,
    version: 1,
    title: '【グループ内複業】複業開始前のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 563,
  },
  {
    fileKey: `${videoDirectory}/doublework/entryuser_2.mp4`,
    thumbnail: `${videoDirectory}/doublework/entryuser_2.png`,
    version: 1,
    title: '【グループ内複業】複業中のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 155,
  },
  {
    fileKey: `${videoDirectory}/doublework/entryuser_3.mp4`,
    thumbnail: `${videoDirectory}/doublework/entryuser_3.png`,
    version: 1,
    title: '【グループ内複業】複業終了後のポイント解説動画',
    completeFraction: 0.7,
    totalSeconds: 119,
  },
]

export const AccountAdminType = {
  isAccountAdmin: 'isAccountAdmin',
  isScoutAccountAdmin: 'isScoutAccountAdmin',
  isSmyleAccountAdmin: 'isSmyleAccountAdmin',
  isDwAccountAdmin: 'isDwAccountAdmin',
} as const
export type AccountAdminType = typeof AccountAdminType[keyof typeof AccountAdminType]

export const AccountAdminTypeName: { [key in AccountAdminType]: string } = {
  [AccountAdminType.isAccountAdmin]: 'キャリアチャレンジ＋ジョブトライアル',
  [AccountAdminType.isScoutAccountAdmin]: 'キャリアスカウト',
  [AccountAdminType.isSmyleAccountAdmin]: 'Smyle',
  [AccountAdminType.isDwAccountAdmin]: 'グループ内複業',
}

export const OrganizationAdminType = {
  CCAdmin: 'isCCAdmin',
  JTAdmin: 'isJTAdmin',
  ScoutAdmin: 'isScoutAdmin',
  ScoutBusinessAdmin: 'isScoutBusinessAdmin',
  SmyleAdmin: 'isSmyleAdmin',
  DWPublisherAdmin: 'isDwPublisherAdmin',
  DWUserAdmin: 'isDwUserAdmin',
}
export type OrganizationAdminType = typeof OrganizationAdminType[keyof typeof OrganizationAdminType]

export const OrganizationAdminTypeName: { [key in OrganizationAdminType]: string } = {
  [OrganizationAdminType.CCAdmin]: 'キャリアチャレンジ',
  [OrganizationAdminType.JTAdmin]: 'ジョブトライアル',
  [OrganizationAdminType.ScoutAdmin]: 'キャリアスカウト(人事)',
  [OrganizationAdminType.ScoutBusinessAdmin]: 'キャリアスカウト(事業人事)',
  [OrganizationAdminType.SmyleAdmin]: 'Smyle',
  [OrganizationAdminType.DWPublisherAdmin]: 'グループ内複業(発注元)',
  [OrganizationAdminType.DWUserAdmin]: 'グループ内複業(輩出元)',
}

export const ExecuteOptionId = {
  ModifyGroupAdminRole: 1,
  AddGroupAdminRole: 2,
  BulkAddGroupAdminRole: 3,
  ModifyAdminRole: 4,
  AddAdminRole: 5,
  BulkAddAdminRole: 6,
  DeleteUser: 7,
  BulkImportUsers: 8,
} as const
export type ExecuteOptionId = typeof ExecuteOptionId[keyof typeof ExecuteOptionId]

// 給与区分
export const SalaryType = {
  NotSelected: 0, // 未選択
  Monthly: 1, // 月給制
  Annual: 2, // 年俸制
} as const
export type SalaryType = typeof SalaryType[keyof typeof SalaryType]

export const SalaryTypeName: { [key in SalaryType]: string } = {
  [SalaryType.NotSelected]: '',
  [SalaryType.Monthly]: '月給制',
  [SalaryType.Annual]: '年俸制',
}

export const CandidateListUserPermission = {
  OrganizationScoutAdmin: 1,
  OrganizationScoutBusinessAdmin: 2,
  OrganizationDepartmentManager: 3,
  OrganizationManager: 4,
}
export type CandidateListUserPermission = typeof CandidateListUserPermission[keyof typeof CandidateListUserPermission]
export const ManagerTitles = ['役員', '部長以上', '課長以上']
export const DepartmentManagerTitles = ['役員', '部長以上']

export const LoaderWarnings = {
  NotExistTag: 'NotExistTag',
  DepartmentWithNotExistOrganization: 'DepartmentWithNotExistOrganization',
  OfficeWithNotExistOrganization: 'OfficeWithNotExistOrganization',
  UserWithEmptyEmail: 'UserWithEmptyEmail',
  UserWithNoEmploymentType: 'UserWithNoEmploymentType',
  UserWithNotExistMainOrganization: 'UserWithNotExistMainOrganization',
  UserWithNotExistHomeOrganization: 'UserWithNotExistHomeOrganization',
  UserWithNoDepartment: 'UserWithNoDepartment',
  MultiPositionDataWithNotExistUser: 'MultiPositionDataWithNotExistUser',
  UserWithNotExistOrganization: 'UserWithNotExistOrganization',
  RawDataWithNotExistUser: 'RawDataWithNotExistUser',
  MultiPositionDataWithNotMatchDepartmentAndOrganization: 'MultiPositionDataWithNotMatchDepartmentAndOrganization',
  SetSettableEmail: 'SetSettableEmail',
  UserOrganizationWithNotExistDepartment: 'UserOrganizationWithNotExistDepartment',
  NonChrUserDeletedEmploymentType: 'NonChrUserDeletedEmploymentType',
  NonChrUserDeletedOrganization: 'NonChrUserDeletedOrganization',
  NonChrUserDeletedDepartment: 'NonChrUserDeletedDepartment',
} as const
export type LoaderWarnings = typeof LoaderWarnings[keyof typeof LoaderWarnings]

export const LoaderWarningsMessage: { [key: string]: string } = {
  [LoaderWarnings.NotExistTag]: '存在しない種別のTagです',
  [LoaderWarnings.DepartmentWithNotExistOrganization]: 'Organizationが存在しないDepartmentです',
  [LoaderWarnings.OfficeWithNotExistOrganization]: 'Organizationが存在しないOfficeです',
  [LoaderWarnings.UserWithEmptyEmail]: 'Emailが空のUserです',
  [LoaderWarnings.UserWithNoEmploymentType]: 'EmploymentTypeが存在しないUserです',
  [LoaderWarnings.UserWithNotExistMainOrganization]:
    'MainOrganizationが存在しないUserです。グループ外企業の所属として登録します。',
  [LoaderWarnings.UserWithNotExistHomeOrganization]: 'HomeOrganizationが存在しないUserです',
  [LoaderWarnings.UserWithNoDepartment]: 'Departmentが存在しないUserです',
  [LoaderWarnings.MultiPositionDataWithNotExistUser]: 'userが存在しない兼務情報です',
  [LoaderWarnings.UserWithNotExistOrganization]:
    'organizationが存在しない兼務情報です。グループ外企業の兼務情報として登録します。',
  [LoaderWarnings.RawDataWithNotExistUser]: 'userが存在しないRawデータです',
  [LoaderWarnings.MultiPositionDataWithNotMatchDepartmentAndOrganization]:
    '兼務情報のDepartmentとOrganizationが一致しません',
  [LoaderWarnings.SetSettableEmail]: '認証に利用中のメールアドレスが変更になったため、settableEmailに設定しました',
  [LoaderWarnings.UserOrganizationWithNotExistDepartment]: 'departmentが存在しない兼務情報です',
  [LoaderWarnings.NonChrUserDeletedEmploymentType]: 'EmploymentTypeが存在しない非CHRユーザです',
  [LoaderWarnings.NonChrUserDeletedOrganization]: 'Organizationが存在しない非CHRユーザです',
  [LoaderWarnings.NonChrUserDeletedDepartment]: 'Departmentが存在しない非CHRユーザです',
}
