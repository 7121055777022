import React, { createContext, useState, useContext } from 'react'
import {
  MutationCreateOrUpdateUserVideoActivity,
  MutationCreateOrUpdateUserVideoActivityResponse,
  QueryLocalState,
  QueryLocalStateResponse,
  QueryVideos,
  QueryVideosResponse,
  Video,
} from '../queries'
import { DoubleworkRequiredVideoDialog, DoubleworkRequiredVideoDialogProps } from '@persol-epdndo/design-systems'
import { RequiredVideoFileKey } from '@persol-epdndo/base-shared'
import { useMutation, useQuery } from '../util'
import { MutationFunctionOptions, OperationVariables, DefaultContext, ApolloCache } from '@apollo/client'
import _ from 'lodash'

interface VideoContextValue {
  doubleworkVideo: Video | undefined
  doubleworkEntryVideo: Video | undefined
  // eslint-disable-next-line prettier/prettier
  refetchVideos: (isEntry: boolean) => Promise<Video | undefined>
  // 必須ビデオを視聴完了していなければダイアログを表示する。チェック結果をbooleanで返す
  // 既に視聴済みだった場合はダイアログを表示する代わりにonClickPrimaryButtonをそのまま実行する
  checkVideoCompletedAndOpenDialog: (
    isEntry: boolean,
    onClickPrimaryButton: DoubleworkRequiredVideoDialogProps['onClickPrimaryButton'],
    onClickCancel?: DoubleworkRequiredVideoDialogProps['onClickCancel'],
  ) => Promise<boolean>
  createOrUpdateUserVideoActivity: (
    options?:
      | MutationFunctionOptions<
          MutationCreateOrUpdateUserVideoActivityResponse,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<any>
}

export const VideoContext = createContext<VideoContextValue | null>(null)

interface VideoProviderProps {
  children: JSX.Element
}

export const VideoProvider = (props: VideoProviderProps) => {
  const dialogDefaultProps = {
    open: false,
    isEntry: false,
    videoFileKey: '',
    videoTitle: '',
    videoVersion: 0,
    videoUrl: '',
    completeFraction: undefined,
    startSeconds: undefined,
    onClickCancel: () => {},
    onClickPrimaryButton: () => {},
    onComplete: async () => {},
    onVideoViewerUnmount: async () => {},
  }

  const [dialogProps, setDialogProps] = useState<DoubleworkRequiredVideoDialogProps>(dialogDefaultProps)

  const { data: lsd } = useQuery<QueryLocalStateResponse>(QueryLocalState)
  const { data: doubleworkVideoData, refetch: refetchDoubleworkVideo } = useQuery<QueryVideosResponse>(QueryVideos, {
    variables: { fileKey: RequiredVideoFileKey.DoubleworkRequired },
    fetchPolicy: 'cache-and-network',
    skip: _.isNil(lsd?.localState.pa?.userId),
  })
  const { data: entryVideoData, refetch: refetchDoubleworkEntryVideo } = useQuery<QueryVideosResponse>(QueryVideos, {
    variables: { fileKey: RequiredVideoFileKey.DoubleworkEntryRequired },
    fetchPolicy: 'cache-and-network',
    skip: _.isNil(lsd?.localState.pa?.userId),
  })

  const entryVideo = entryVideoData?.videos[0]
  const doubleworkVideo = doubleworkVideoData?.videos[0]

  const refetchVideos = async (isEntry: boolean) => {
    isEntry ? await refetchDoubleworkEntryVideo() : await refetchDoubleworkVideo()
    return isEntry ? entryVideo : doubleworkVideo
  }

  const [createOrUpdateUserVideoActivity] = useMutation<MutationCreateOrUpdateUserVideoActivityResponse>(
    MutationCreateOrUpdateUserVideoActivity,
    {
      onCompleted(data, clientOptions) {
        // videoのhasNewVersionやisLatestVideoCompletedもキャッシュを更新するためにrefetchしている
        refetchVideos(clientOptions?.variables?.input.fileKey === RequiredVideoFileKey.DoubleworkEntryRequired)
      },
    },
  )

  const openDialog = (
    isEntry: boolean,
    onClickPrimaryButton: DoubleworkRequiredVideoDialogProps['onClickPrimaryButton'],
    onClickCancel?: DoubleworkRequiredVideoDialogProps['onClickCancel'],
  ) => {
    const video = isEntry ? entryVideo : doubleworkVideo
    if (video !== undefined) {
      setDialogProps({
        open: true,
        isEntry: isEntry,
        videoFileKey: video.fileKey,
        videoTitle: video.title,
        videoVersion: video.version,
        videoUrl: video.fileUrl,
        completeFraction: video.completeFraction,
        startSeconds:
          // 最後に見たものとバージョンが違う場合は最初から再生する
          video.version === video.userVideoActivity?.lastWatchedVersion
            ? video.userVideoActivity?.lastWatchedSeconds
            : 0,
        onClickCancel: () => {
          if (onClickCancel !== undefined) onClickCancel()
          setDialogProps(value => {
            return { ...value, open: false }
          })
        },
        onClickPrimaryButton: () => {
          onClickPrimaryButton()
          setDialogProps(value => {
            return { ...value, open: false }
          })
        },
        onComplete: async (fileKey, version) => {
          await createOrUpdateUserVideoActivity({
            variables: {
              input: {
                fileKey: fileKey,
                videoVersion: version,
                isCompletedVideo: true,
              },
            },
          })
        },
        onVideoViewerUnmount: async (fileKey, version, seconds) => {
          await createOrUpdateUserVideoActivity({
            variables: {
              input: {
                fileKey: fileKey,
                videoVersion: version,
                // DBでは整数で持っているので小数点以下切り捨て
                lastWatchedSeconds: Math.floor(seconds),
              },
            },
          })
        },
      })
    }
  }

  const checkVideoCompletedAndOpenDialog = async (
    isEntry: boolean, // エントリー用動画かどうか
    onClickPrimaryButton: DoubleworkRequiredVideoDialogProps['onClickPrimaryButton'],
    onClickCancel?: DoubleworkRequiredVideoDialogProps['onClickCancel'],
  ) => {
    // チェックの前にrefetch
    isEntry ? await refetchDoubleworkEntryVideo() : await refetchDoubleworkVideo()
    const video = isEntry ? entryVideo : doubleworkVideo
    if (video === undefined) {
      console.error('video is undefined')
      return false
    }

    if (!video.isLatestVideoCompleted) {
      openDialog(isEntry, onClickPrimaryButton, onClickCancel)
      return false
    }

    onClickPrimaryButton()
    return true
  }

  return (
    <>
      <VideoContext.Provider
        value={{
          doubleworkVideo: doubleworkVideo,
          doubleworkEntryVideo: entryVideo,
          refetchVideos,
          checkVideoCompletedAndOpenDialog,
          createOrUpdateUserVideoActivity,
        }}
      >
        {props.children}
      </VideoContext.Provider>
      <DoubleworkRequiredVideoDialog {...dialogProps} />
    </>
  )
}

// Contextを使うためのカスタムフック
export const useVideoContext = () => {
  const context = useContext(VideoContext)
  if (context === undefined) {
    throw new Error('Provider内で使用してください')
  }
  return context
}
