import { Auth } from '@aws-amplify/auth'

export function initAuth() {
  Auth.configure({
    region: 'ap-northeast-1',
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_CLIENT_ID,
    identityPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_OAUTH_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOL_OAUTH_REDIRECT_URL,
      responseType: 'code',
    },
  })
}
