import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import isNil from 'lodash/isNil'
import { AppProps } from 'next/app'
import { useApollo, initializeApollo } from '../lib/apollo'
import { AuthProvider, AuthProps } from '../providers/AuthProvider'
import { hooks, mutations } from '../lib/state'
import { Hub } from '@aws-amplify/core'
import { ApolloProvider, useSubscription } from '@apollo/client'
import { DefaultTheme, Dialog, Typography, Button } from '@persol-epdndo/design-systems'
import {
  ThemeProvider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CssBaseline,
} from '@material-ui/core'
import nl2br from 'react-nl2br'
import { AgreementProvider, AgreementProps } from '../providers/AgreementProvider'
import { HistoryProvider } from '../providers/HistoryProvider'
import { ScrollProvider } from '../providers/ScrollProvider'
import { MutationAddAgreement, SubscriptionReloadBrowser } from '../queries'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayJsUtils from '@date-io/dayjs'
import { SnackbarProvider, SnackbarKey } from 'notistack'
import { initAnalytics, sendEvent } from '../lib/analytics'
import { initAuth } from '../lib/auth'
import { useRouter } from 'next/router'
import CommonMeta from '../components/CommonMeta'
import { getIsPrivateNavigation } from '../util'
import 'react-image-crop/dist/ReactCrop.css'
import _ from 'lodash'
import { VideoProvider } from '../providers/VideoProvider'

initAuth()
initAnalytics()
export interface PageProps {
  navigations: React.ReactElement | React.ReactElement[]
  authProps: Partial<AuthProps>
  agreementProps: Partial<AgreementProps>
}

export const DefaultAuthProps: AuthProps = {
  roles: [],
  guestOnly: false,
}

export const DefaultAgreementProps: AgreementProps = {
  onHasNoAgreement: customTerms => {
    const handleAgree = async () => {
      await initializeApollo().mutate({ mutation: MutationAddAgreement })
      await mutations.refreshSession()
    }

    const handleDisagree = async () => {
      await mutations.signOut('/')
    }

    return (
      <Dialog open>
        <DialogTitle disableTypography>
          <Typography variant='h6'>{customTerms.title}</Typography>
          <Typography variant='subtitle1'>{customTerms.subTitle}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{nl2br(customTerms.content)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={handleDisagree} data-testid='dialog-cancel-button'>
            同意しない
          </Button>
          <Button variant='text' onClick={handleAgree} data-testid='dialog-ok-button'>
            同意する
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
}

function getSnackbarProviderProps(ref: React.RefObject<SnackbarProvider>) {
  // NOTE: e2e環境専用
  // エラーや成功メッセージをAutify側で明確に確認できるように永久表示、動的に削除できるようにしている
  if (process.env.NEXT_PUBLIC_API_SERVER_ENDPOINT === 'https://api.e2e.careermill.epdndo.com/graphql') {
    const onClickSnakbarClose = (key: SnackbarKey) => () => {
      ref?.current?.closeSnackbar(key)
    }

    return {
      maxSnack: 3,
      preventDuplicate: true,
      autoHideDuration: null,
      ref,
      action: (key: SnackbarKey) => (
        <Button variant='text' color='inherit' data-testid='snackbar-close-button' onClick={onClickSnakbarClose(key)}>
          x
        </Button>
      ),
    }
  }

  return {
    maxSnack: 3,
    preventDuplicate: true,
  }
}

export const SubscriptionReload = (props: { children: JSX.Element }): JSX.Element => {
  // NOTE: ページをリロードするsubscriptionを受けている。ログイン前の画面でもリロードさせられるようにここで処理している
  useSubscription(SubscriptionReloadBrowser, {
    onData: () => {
      window.location.reload()
    },
    onError: error => {
      console.error(error)
    },
  })
  return props.children
}

export default function App({ Component, pageProps }: AppProps<PageProps>) {
  const apolloClient = useApollo()
  const router = useRouter()
  const navs = Component.defaultProps?.navigations ?? []
  const notistackRef = React.createRef<SnackbarProvider>()

  // コンポーネントがマウントされた初回に１回だけ実行されるよう第２引数に空の配列を指定
  useEffect(() => {
    if (isNil(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID)) {
      return
    }
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    })
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', (path: string) => {
      sendEvent({
        type: 'pageview',
        url: `${document.location.origin}${path}`,
        prevUrl: document.location.href,
        target: null,
      })
    })
    document.addEventListener(
      'click',
      event => {
        sendEvent({
          type: 'click',
          url: document.location.href,
          prevUrl: '',
          target: event.target as HTMLElement | null,
        })
      },
      { capture: true },
    )
    // NOTE: トークンのリフレッシュに失敗した場合はログアウトさせる
    Hub.listen('auth', data => {
      if (data.payload.event === 'tokenRefresh_failure') {
        mutations.signOut()
      }
    })
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(
          registration => {
            console.log('Service Worker registration successful with scope: ', registration.scope)
          },
          err => {
            console.error('Service Worker registration failed: ', err)
          },
        )
      })
    }
  }, [])

  return (
    <>
      <CommonMeta />
      <ThemeProvider theme={DefaultTheme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DayJsUtils} locale='ja'>
          <SnackbarProvider {...getSnackbarProviderProps(notistackRef)} style={{ whiteSpace: 'pre-line' }}>
            <ApolloProvider client={apolloClient}>
              <SubscriptionReload>
                <AuthProvider
                  authHook={hooks.useAuth}
                  defaultProps={DefaultAuthProps}
                  pageProps={Component.defaultProps?.authProps}
                  isPrivateNavigation={getIsPrivateNavigation(navs)}
                >
                  <AgreementProvider
                    agreementHook={hooks.useAgreement}
                    defaultProps={DefaultAgreementProps}
                    pageProps={Component.defaultProps?.agreementProps}
                    needToCheckAggrement={!_.isEmpty(Component.defaultProps?.authProps?.roles)}
                  >
                    <HistoryProvider>
                      <ScrollProvider>
                        <VideoProvider>
                          {(Array.isArray(navs) ? navs : [navs]).reduceRight((acc, navigationComponent) => {
                            return React.cloneElement(navigationComponent, {}, acc)
                          }, <Component {...pageProps} />)}
                        </VideoProvider>
                      </ScrollProvider>
                    </HistoryProvider>
                  </AgreementProvider>
                </AuthProvider>
              </SubscriptionReload>
            </ApolloProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      <style jsx global>
        {`
          #__next {
            margin: 0;
            padding: 0;
          }
        `}
      </style>
    </>
  )
}
