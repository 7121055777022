import { makeVar, TypePolicy } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import {
  QueryAccountResponse,
  QueryAccount,
  QueryLocalStateAndCustomTerms,
  QueryLocalStateAndCustomTermsResponse,
  QueryLocalState,
  QueryLocalStateResponse,
} from '../queries'
import { useQuery } from '../util'
import {
  AuthRole,
  checkAuth,
  getUserPermissionAttributes,
  RoleOrRoleArray,
  TokenPayload,
  UserPermissionAttributes,
  emailToCognitoUsername,
} from '@persol-epdndo/base-shared'
import { initializeApollo } from './apollo'
import router, { useRouter } from 'next/router'
import { parseJwk } from 'jose/jwk/parse'
import { CompactEncrypt } from 'jose/jwe/compact/encrypt'

export interface LocalState {
  pa: UserPermissionAttributes | null
}

// NOTE: 直接操作できないようにexportはしない
const localState = {
  pa: makeVar<UserPermissionAttributes | null>(null),
}

export const apolloLocalStateCachePolicy: TypePolicy = {
  fields: {
    pa: {
      read() {
        return localState.pa()
      },
    },
  },
}

export const hooks = {
  useAuth(roles: RoleOrRoleArray) {
    const router = useRouter()
    const [loading, setLoading] = useState<boolean>(true)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [authorized, setAuthorized] = useState<boolean>(false)
    const [pa, setPa] = useState<UserPermissionAttributes | null>(null)
    const { loading: queryLoading, data } = useQuery<QueryLocalStateResponse>(QueryLocalState)
    useEffect(() => {
      ;(async () => {
        await mutations.checkSession()
      })()
    }, [router.asPath])
    useEffect(() => {
      if (!queryLoading && data !== undefined && data.localState.pa !== null) {
        setLoading(false)
        setLoggedIn(data.localState.pa.roles.includes(AuthRole.LoginUser) ?? false)
        setAuthorized(checkAuth(data.localState.pa, roles))
        setPa(data.localState.pa)
      }
    }, [queryLoading, data, roles])

    return { loading, loggedIn, authorized, pa }
  },
  useAgreement() {
    const [loading, setLoading] = useState(true)
    const [agreementRequired, setAgreementRequired] = useState(true)
    const { loading: queryLoading, data, refetch } = useQuery<QueryLocalStateAndCustomTermsResponse>(
      QueryLocalStateAndCustomTerms,
      {
        fetchPolicy: 'cache-first',
      },
    )
    useEffect(() => {
      if (!queryLoading && data !== undefined) {
        setLoading(false)
        setAgreementRequired(data.localState.pa !== null && !data.localState.pa.hasAgreement)
      }
    }, [queryLoading, data])
    useEffect(() => {
      if (agreementRequired) refetch()
    }, [agreementRequired])
    return { loading, agreementRequired, customTerms: data?.account.customTerms ?? null }
  },
}

export const mutations = {
  getCurrentInfo() {
    return localState.pa()
  },
  async checkToken() {
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken()
      const jwtToken = idToken.getJwtToken()
      const payload = idToken.payload as TokenPayload
      return { payload, jwtToken }
    } catch {
      return { payload: null, jwtToken: null }
    }
  },
  async checkSession() {
    const { payload, jwtToken } = await this.checkToken()
    if (jwtToken === null) {
      return localState.pa(getUserPermissionAttributes(null))
    }
    const { data } = await initializeApollo().query<QueryAccountResponse>({
      query: QueryAccount,
      fetchPolicy: 'cache-first',
    })
    return localState.pa(
      getUserPermissionAttributes(
        payload,
        undefined,
        undefined,
        undefined,
        data?.account.allowIp,
        data?.account.customTermsUpdatedAt ?? null,
      ),
    )
  },
  async signUp(email: string, password: string, attributes?: object) {
    return await Auth.signUp({
      username: emailToCognitoUsername(email),
      password,
      attributes: { email, ...attributes },
    })
  },
  async confirmSignUp(username: string, code: string) {
    await Auth.confirmSignUp(username, code)
  },
  async forgotPassword(email: string) {
    await Auth.forgotPassword(email)
  },
  async forgotPasswordSubmit(username: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(username, code, password)
  },
  async resendSignUp(username: string) {
    await Auth.resendSignUp(username)
  },
  async signIn(username: string, password: string) {
    await Auth.signIn({
      username,
      password,
    })
    await this.checkSession()
  },
  async signInWithAd(username: string, password: string) {
    const resp = await Auth.signIn(username)
    const key = await parseJwk(JSON.parse(resp.challengeParam.key), 'RSA-OAEP')
    const encoder = new TextEncoder()
    const jwe = await new CompactEncrypt(encoder.encode(password))
      .setProtectedHeader({ alg: 'RSA-OAEP', enc: 'A256GCM' })
      .encrypt(key)
    await Auth.sendCustomChallengeAnswer(resp, jwe)
    await this.checkSession()
  },
  async signOut(replaceUrl?: string) {
    await Auth.signOut()
    await this.checkSession()
    if (replaceUrl !== undefined) {
      await router.replace(replaceUrl)
    }
    // NOTE:
    // apollo-clientのclearStoreが動かないので暫定的に。
    location.reload()
  },
  async refreshSession() {
    const currentSession = await Auth.currentSession()
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
    localState.pa(null)
    cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
      this.checkSession()
    })
  },
}
