import { InputType } from './const'

// 事後アンケート20代メンバー（2023/1/24以前）
export const PostSurveyV1Detail = {
  careerInventory: {
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      '他者に考えを話すことにより刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は「特になし」とご記入ください。)',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  changeInAwarenessAndMotivation: {
    questionNumber: 'Q6',
    question: 'ワークショップが終わった後、意識や意欲に変化がありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  changeInAwarenessAndMotivationReasons: {
    questionNumber: 'Q7',
    question:
      'Q6の理由をお答えください。(意識や意欲にどのような変化を感じているのか、また取り組み始めたことなどあれば、具体的にご記入ください。Q6で「どちらでもない」「あまりそう思わない」「そう思わない」と回答された場合は、変化がない理由をご記入ください。)',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  recommendToOthers: {
    questionNumber: 'Q8',
    question: '本ワークショップを周りの人にも紹介したいと思われますか？0～10でご回答ください。',
    type: InputType.Slider,
    defaultValue: 5,
    min: 0,
    max: 10,
    marks: [
      { value: 0, label: 'まったく思わない' },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, label: 'どちらでもない' },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, label: '非常にそう思う' },
    ],
    valueLabelDisplay: 'on' as const,
    required: true,
    length: {},
  },
  recommendToOthersReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  willingnessToStepForward: {
    questionNumber: 'Q10',
    question:
      '参加をきっかけとして、「自分のキャリアを自分で決める」ための一歩を踏み出してみたいという意欲はわきましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wasSpeakerHelpful: {
    questionNumber: 'Q11',
    question: '登壇いただいた2名のインタビューはご自身のキャリアを考える上で参考になりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wasSpeakerHelpfulReasons: {
    questionNumber: 'Q12',
    question: 'Q11でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q13',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 事後アンケート20代以外メンバー
export const PostSurveyV2Detail = {
  careerInventory: {
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      '他者に考えを話すことにより刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は、「特になし」とご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q4の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  changeInAwarenessAndMotivation: {
    questionNumber: 'Q6',
    question: 'ワークショップが終わった後、意識や意欲に変化がありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  changeInAwarenessAndMotivationReasons: {
    questionNumber: 'Q7',
    question:
      'Q6の理由をお答えください。(意識や意欲にどのような変化を感じているのか、また取り組み始めたことなどあれば、具体的にご記入ください。「どちらでもない」「あまりそう思わない」「そう思わない」と回答された場合は、変化がない理由をご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  recommendToOthers: {
    questionNumber: 'Q8',
    question: '本ワークショップを周りの人にも紹介したいと思われますか？0～10でご回答ください。',
    type: InputType.Slider,
    defaultValue: 5,
    min: 0,
    max: 10,
    marks: [
      { value: 0, label: 'まったく思わない' },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, label: 'どちらでもない' },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, label: '非常にそう思う' },
    ],
    valueLabelDisplay: 'on' as const,
    required: true,
    length: {},
  },
  recommendToOthersReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q8の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  willingnessToStepForward: {
    questionNumber: 'Q10',
    question:
      '参加をきっかけとして、「自分のキャリアを自分で決める」ための一歩を踏み出してみたいという意欲はわきましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  others: {
    questionNumber: 'Q11',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 事後アンケート管理職
export const PostSurveyV3Detail = {
  understandingOfNeedToSupportSubordinates: {
    questionNumber: 'Q1',
    question: '部下のキャリアデザイン支援を行う必要性は理解できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともと理解できていた'],
    required: true,
    length: {},
  },
  understandingOfStepsSkillsAndMindset: {
    questionNumber: 'Q2',
    question: 'キャリアデザイン支援のステップや必要なスキル・マインドの『理解』はできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  senseOfStepsSkillsAndMindset: {
    questionNumber: 'Q3',
    question:
      'グループワークを通して、キャリアデザイン支援のステップや必要なスキル・マインドの『体感』を得られましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  whatAbleToDo: {
    questionNumber: 'Q4',
    question: '全般を通して、「できた」と思う項目を"すべて"選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      '管理職としての部下のキャリアデザイン支援を行う役割であることの再認識ができた',
      '社員が自律的にキャリアを考える効果を認識できた',
      'キャリアデザイン支援のステップが理解できた',
      '深堀りや引き出すのための質問を認識できた',
      'キャリア展望と目標設定の接続方法を理解できた',
      '多彩なアプローチ方法を知ることができた',
      'キャリアについて対話することによる効果を感じることができた(部下の気持ちを感じることができた)',
      '部下のキャリアに向き合うイメージを持つことができた',
      '部下のキャリア支援を実践してみたいと感じる事ができた',
      '自己理解を深める事ができた',
      '改めて、自身のキャリアを考えるきっかけにする事ができた',
      '他者との交流により刺激を受けることができた',
    ],
    required: true,
    length: { min: 1 },
  },
  whatMakeUseOf: {
    questionNumber: 'Q5',
    question:
      'トレーニングの中で、部下のキャリアデザイン支援において特に活かせると思われたことをご記入ください。(特に活かせると思ったことがなかった場合、「特になし」とご記入ください。)',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  changeInAwarenessAndMotivation: {
    questionNumber: 'Q6',
    question: 'ワークショップが終わった後、部下のキャリアデザイン支援において意識や、意欲に変化はありますか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  changeInAwarenessAndMotivationReasons: {
    questionNumber: 'Q7',
    question:
      'Q6の理由をお答えください。(意識や意欲にどのような変化を感じているのか、また取り組み始めたことなどあれば、具体的にご記入ください。Q6で「どちらでもない」「あまりそう思わない」「そう思わない」と回答された場合は、変化がない理由をご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  whatDoingInFuture: {
    questionNumber: 'Q8',
    question:
      '部下のキャリアデザイン支援として、これから取り組もうと考えていることを具体的にご記入ください。(どのような場面で、どのようなことをしようと考えていますか？)',
    type: InputType.Text,
    required: true,
    length: { min: 1, max: 400 },
  },
  wantToConnectWill: {
    questionNumber: 'Q9',
    question:
      '部下のキャリアデザイン支援を通じて、志向性を把握し、個人のWILLと組織のWILLをつなげることをしたいと感じましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  recommendToOthers: {
    questionNumber: 'Q10',
    question:
      '本トレーニングを周りの管理職(未受講・今後管理職予定の方)にも紹介したいと思われますか？0～10でご回答ください。',
    type: InputType.Slider,
    defaultValue: 5,
    min: 0,
    max: 10,
    marks: [
      { value: 0, label: 'まったく思わない' },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, label: 'どちらでもない' },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, label: '非常にそう思う' },
    ],
    valueLabelDisplay: 'on' as const,
    required: true,
    length: {},
  },
  recommendToOthersReasons: {
    questionNumber: 'Q11',
    question: 'Q10でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q10の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q12',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 事後アンケート20代メンバー（2023/1/25以降）
export const PostSurveyV4Detail = {
  careerInventory: {
    previewChip: 'Smyleキャリアデザインワークショップに関して',
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      '他者に考えを話すことにより刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は「特になし」とご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q4の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  changeInAwarenessAndMotivation: {
    questionNumber: 'Q6',
    question: 'ワークショップが終わった後、意識や意欲に変化がありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  changeInAwarenessAndMotivationReasons: {
    questionNumber: 'Q7',
    question:
      'Q6の理由をお答えください。(意識や意欲にどのような変化を感じているのか、また取り組み始めたことなどあれば、具体的にご記入ください。Q6で「どちらでもない」「あまりそう思わない」「そう思わない」と回答された場合は、変化がない理由をご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  recommendToOthers: {
    questionNumber: 'Q8',
    question: '本ワークショップを周りの人にも紹介したいと思われますか？0～10でご回答ください。',
    type: InputType.Slider,
    defaultValue: 5,
    min: 0,
    max: 10,
    marks: [
      { value: 0, label: 'まったく思わない' },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, label: 'どちらでもない' },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, label: '非常にそう思う' },
    ],
    valueLabelDisplay: 'on' as const,
    required: true,
    length: {},
  },
  recommendToOthersReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q8の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  willingnessToStepForward: {
    questionNumber: 'Q10',
    question:
      '参加をきっかけとして、「自分のキャリアを自分で決める」ための一歩を踏み出してみたいという意欲はわきましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wasSpeakerHelpful: {
    questionNumber: 'Q11',
    question: '登壇いただいた2名のインタビューはご自身のキャリアを考える上で参考になりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wasSpeakerHelpfulReasons: {
    questionNumber: 'Q12',
    question: 'Q11でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q11の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q13',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
  willingnessToManage: {
    previewChip: '管理職着任に関して',
    preDescription:
      'パーソルグループの”Diversity, Inclusion & Equality”の一貫で管理職着任に関するアンケートのご協力をお願いできますでしょうか。所属会社ごとに管理職の定義が異なるため、ここでは管理職＝組織長を想定してご質問をさせていただきます。',
    secondaryPreDescription: '*回答は統計的に処理され、個人が特定されることはありません。',
    questionNumber: 'Q1',
    question: 'あなたの管理職(おもに組織長)に対する着任意向に最も近いものを選択してください。',
    type: InputType.Select,
    options: ['ぜひ着任したい', '機会があれば着任したい', 'わからない', 'あまり着任したくない', '絶対着任したくない'],
    required: true,
    length: {},
  },
  positiveReasons: {
    conditionsToBeDisabled: {
      target: 'willingnessToManage',
      includes: ['わからない', 'あまり着任したくない', '絶対着任したくない'],
    },
    questionNumber: 'Q2',
    question: `Q1で「機会があれば着任したい」「ぜひ着任したい」を選択された方のみご回答下さい：
ご自身の意向の理由に最も近いものを最大3つまで選んでください。`,
    type: InputType.SelectMultiple,
    options: [
      '憧れがある',
      '責任範囲や扱う金額などより裁量を持ちたい',
      '組織マネジメント・育成に興味がある',
      '成長したい、スキルアップしたい',
      'より高度な業務にチャレンジしたい',
      'より大きな影響力を周囲に及ぼしたい、社内で周囲に頼られたい',
      '会社からの評価を感じられると思う',
      'キャリアアップしたい、社会的地位を上げたい',
      '報酬・待遇面でのメリットがあると感じる',
      '自分の能力や適性を活かせると感じる',
      '役割を果たし、成果を出せると感じる',
      'その他(自由入力)',
    ],
    required: true,
    arrayLength: { max: 3 },
    length: { min: 1 },
  },
  notKnowingReasons: {
    conditionsToBeDisabled: {
      target: 'willingnessToManage',
      includes: ['ぜひ着任したい', '機会があれば着任したい', 'あまり着任したくない', '絶対着任したくない'],
    },
    questionNumber: 'Q3',
    question: `Q1で「わからない」を選択された方のみご回答下さい：
どのような情報があれば判断できると感じますか？最もあてはまるものを最大3つまで選んでください。`,
    type: InputType.SelectMultiple,
    options: [
      '管理職の魅力・やりがいなどポジティブな点',
      '管理職のデメリット・ネガティブな点',
      '業務を通じて獲得できるスキル・専門性',
      '業務に求められる適性・スキル',
      '自分が持っている適性・スキル',
      'プライベートを含めたはたらき方',
      '報酬・待遇面の変化',
      '管理職の普段の時間の使い方・具体的な業務内容',
      '自分にとって理想なロールモデルの明確化',
      '会社・SBUの方針',
      '会社/組織から自分への期待・育成方針',
      '社会的な「管理職」の位置づけ・見え方',
      'その他(自由入力)',
    ],
    required: true,
    arrayLength: { max: 3 },
    length: { min: 1 },
  },
  negativeReasons: {
    conditionsToBeDisabled: {
      target: 'willingnessToManage',
      includes: ['ぜひ着任したい', '機会があれば着任したい', 'わからない'],
    },
    questionNumber: 'Q4',
    question: `Q1で「絶対着任したくない」「あまり着任したくない」を選択された方のみご回答下さい：
ご自身の意向の理由に最も近いものを最大3つまで選んでください。`,
    type: InputType.SelectMultiple,
    options: [
      '業務内容のイメージがつかない',
      'やりたい業務ができなくなると感じる',
      '組織マネジメント・育成に興味がない',
      '自分にとって成長を感じられないと思う',
      '専門性が育たないと感じる',
      'より大きな責任を持ちたくない',
      '調整作業など間接業務が増えると感じる',
      '管理職の普段の時間の使い方・具体的な業務内容',
      'プライベートとの両立のイメージがない',
      '報酬・待遇面でのメリットが少ないと感じる',
      '時間的な業務負荷が多いと感じる',
      '自分の適性とマッチしない、向いていないと感じる',
      '役割を果たす自信がない',
      'その他(自由入力)',
    ],
    required: true,
    arrayLength: { max: 3 },
    length: { min: 1 },
  },
}

// 事後アンケート20代メンバー(2023上期)
export const PostSurveyV5Detail = {
  careerInventory: {
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      '他者に考えを話すことにより刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は「特になし」とご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q4の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  wantToBuildMyOwnCareer: {
    questionNumber: 'Q6',
    question: '本研修に参加したことで、より自分らしいキャリアを築きたいという気持ちになりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wantToBuildMyOwnCareerReasons: {
    questionNumber: 'Q7',
    question: 'Q6でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  becomeMoreActiveTowardsRealizingMyOwnCareer: {
    questionNumber: 'Q8',
    question: '本研修に参加したことで、自分らしいキャリアの実現に向けて、より行動しやすくなりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q8の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  wasSpeakerHelpful: {
    questionNumber: 'Q10',
    question: '登壇いただいた2名のインタビューはご自身のキャリアを考える上で参考になりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wasSpeakerHelpfulReasons: {
    questionNumber: 'Q11',
    question: 'Q10でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q10の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q12',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 事後アンケート30代・40代メンバー(2023上期)
export const PostSurveyV6Detail = {
  careerInventory: {
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      '他者に考えを話すことにより刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は、「特になし」とご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q4の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  wantToBuildMyOwnCareer: {
    questionNumber: 'Q6',
    question: '本研修に参加したことで、より自分らしいキャリアを築きたいという気持ちになりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまり思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wantToBuildMyOwnCareerReasons: {
    questionNumber: 'Q7',
    question: 'Q6でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  becomeMoreActiveTowardsRealizingMyOwnCareer: {
    questionNumber: 'Q8',
    question: '本研修に参加したことで、自分らしいキャリアの実現に向けて、より行動しやすくなりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまり思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q8の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q10',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 事後アンケート メンバー共通(2024下期追加)
export const PostSurveyV7Detail = {
  careerInventory: {
    questionNumber: 'Q1',
    question: '事前ワーク、ワークショップでの対話を通じて、ご自身のこれまでのキャリアの棚卸しはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  willMustCanVerbalization: {
    questionNumber: 'Q2',
    question: 'ワークショップでの対話を通じて、ご自身のWILL/MUST/CANを言語化することはできましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  actionPlanVerbalization: {
    questionNumber: 'Q3',
    question: 'ワークショップでの対話を通じて、ご自身のこの先3年間のアクションプランは言語化できましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'あまりそう思わない', 'そう思わない', 'もともとできていたので、変化はなし'],
    required: true,
    length: {},
  },
  workshopEffectiveness: {
    questionNumber: 'Q4',
    question:
      'このワークショップは、ご自身にとってどのような点で効果があったと思いますか？選択肢の中で、なんらか効果があったと思う項目を選択ください。(複数選択可)',
    type: InputType.SelectMultiple,
    options: [
      'キャリアを考えることの必要性・動機付けができた',
      '自己理解が深まった',
      '成長機会を自ら創出する意識醸成ができた',
      '健全な危機感が醸成された',
      'キャリア展望の言語化ができた',
      '現業務とのキャリアの接続ができた',
      '自ら役割創出と価値提供プランの立案ができた',
      // V6からこの選択肢のみ変更
      '他者と対話することで新たな気づきや刺激を受けた',
      'キャリアを考えるきっかけになった',
      'キャリアを考えるフレームの理解ができた',
      'やる気(または覚悟)が醸成された',
      '特になし',
    ],
    required: true,
    length: { min: 1 },
  },
  workshopEffectivenessReasons: {
    questionNumber: 'Q5',
    question:
      'Q4でそう思われる理由をお聞かせください。(具体的にどのような点が効果的だったのかなどをお聞かせください。Q4で「特になし」を選択された方は、「特になし」とご記入ください。)',
    type: InputType.Text,
    placeholder: 'Q4の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  wantToBuildMyOwnCareer: {
    questionNumber: 'Q6',
    question: '本研修に参加したことで、より自分らしいキャリアを築きたいという気持ちになりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまり思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  wantToBuildMyOwnCareerReasons: {
    questionNumber: 'Q7',
    question: 'Q6でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q6の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  becomeMoreActiveTowardsRealizingMyOwnCareer: {
    questionNumber: 'Q8',
    question: '本研修に参加したことで、自分らしいキャリアの実現に向けて、より行動しやすくなりましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまり思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: {
    questionNumber: 'Q9',
    question: 'Q8でそう思われる理由をお聞かせください。',
    type: InputType.Text,
    placeholder: 'Q8の理由',
    required: true,
    length: { min: 1, max: 400 },
  },
  others: {
    questionNumber: 'Q10',
    question: 'その他、ご意見、ご感想、今後に向けた改善点等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

export const PostSurveyDetail = {
  PostSurveyV1: PostSurveyV1Detail,
  PostSurveyV2: PostSurveyV2Detail,
  PostSurveyV3: PostSurveyV3Detail,
  PostSurveyV4: PostSurveyV4Detail,
  PostSurveyV5: PostSurveyV5Detail,
  PostSurveyV6: PostSurveyV6Detail,
  PostSurveyV7: PostSurveyV7Detail,
}

// 半年後アンケート20代メンバー
export const HalfYearLaterSurveyV1Detail = {
  careersOfInterest: {
    questionNumber: 'Q1',
    question: 'ワークショップ参加以降、現在どのようなキャリアプランに関心がありますか？（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '複業や越境などで自分の領域外に関心がある',
      '今の仕事の領域でエキスパート/スペシャリストへ関心がある',
      'マネジメントを目指していきたい',
      'プライベートを優先した仕事をしていきたい',
      '今とは別の職種や業界にチャレンジしていきたい',
      'このまま今の仕事を続けていきたい',
      'その他(自由入力)',
    ],
    required: true,
    length: {},
  },
  changesInAttitudesAndBehavior: {
    questionNumber: 'Q2',
    question: 'ワークショップ参加以降、この間の変化を伺います。意識や行動に変化はありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  haveTakenAction: {
    questionNumber: 'Q3',
    question: 'ワークショップ参加以降、「自分のキャリアを自分で決める」ための取り組みをしましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  whatWorkingOn: {
    questionNumber: 'Q4',
    question: 'ワークショップ参加後、具体的に取り組んでいることがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '自己資源の棚卸を実施した',
      'キャリア展望の言語化を実施した',
      '現業務とキャリアの接続を実施した',
      '3年間の具体的なアクションプランの言語化を実施した',
      '自ら役割や成長機会創出の検討・実施をした',
      '上司とキャリアデザインについて対話を実施した',
      '社外セミナーや社外交流など情報収集を実施した',
      '直近の目標設定の再設定を実施した',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  whatHasChanged: {
    questionNumber: 'Q5',
    question: 'Q4で具体的に取り組んだ結果、変化したことがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '自己理解が深まった',
      'WILL/MUST/CANがより明確になった',
      '現業務とキャリアの接続ができるようになった',
      '現業務に対して前向きに取り組めるようになった',
      '現状所属組織への貢献意欲が高まった',
      '上司と関係性が深まった',
      'パフォーマンスが向上した',
      '周囲からのフィードバックが変わった',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  others: {
    questionNumber: 'Q6',
    question: 'その他、ご意見等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 半年後アンケート20代以外メンバー
export const HalfYearLaterSurveyV2Detail = {
  changesInAttitudesAndBehavior: {
    questionNumber: 'Q1',
    question: 'ワークショップ参加以降、この間の変化を伺います。意識や行動に変化はありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  haveTakenAction: {
    questionNumber: 'Q2',
    question: 'ワークショップ参加以降、「自分のキャリアを自分で決める」ための取り組みをしましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  whatWorkingOn: {
    questionNumber: 'Q3',
    question: 'ワークショップ参加後、具体的に取り組んでいることがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '自己資源の棚卸を実施した',
      'キャリア展望の言語化を実施した',
      '現業務とキャリアの接続を実施した',
      '3年間の具体的なアクションプランの言語化を実施した',
      '自ら役割や成長機会創出の検討・実施をした',
      '上司とキャリアデザインについて対話を実施した',
      '社外セミナーや社外交流など情報収集を実施した',
      '直近の目標設定の再設定を実施した',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  whatHasChanged: {
    questionNumber: 'Q4',
    question: 'Q3で具体的に取り組んだ結果、変化したことがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '自己理解が深まった',
      'WILL/MUST/CANがより明確になった',
      '現業務とキャリアの接続ができるようになった',
      '現業務に対して前向きに取り組めるようになった',
      '現状所属組織への貢献意欲が高まった',
      '上司と関係性が深まった',
      'パフォーマンスが向上した',
      '周囲からのフィードバックが変わった',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  others: {
    questionNumber: 'Q5',
    question: 'その他、ご意見等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

// 半年後アンケート管理職
export const HalfYearLaterSurveyV3Detail = {
  changesInAttitudesAndBehavior: {
    questionNumber: 'Q1',
    question: 'キャリアデザイントレーニング参加以降、この間の変化を伺います。意識や行動に変化はありましたか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  haveTakenAction: {
    questionNumber: 'Q2',
    question:
      'キャリアデザイントレーニング参加以降、部下のキャリアデザイン支援を通じて、志向性を把握し、個人のWILLと組織のWILLをつなげることを実践していますか？',
    type: InputType.Select,
    options: ['そう思う', 'ややそう思う', 'どちらでもない', 'あまりそう思わない', 'そう思わない'],
    required: true,
    length: {},
  },
  whatWorkingOn: {
    questionNumber: 'Q3',
    question:
      'キャリアデザイン支援トレーニング参加後、部下のキャリアデザイン支援において具体的に取り組んでいることがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '意識的に深堀や引き出すための問いかけを行うようになった',
      '意識的にアプローチ方法を変えてみた（相談窓口の紹介、研修の案内、他部署の先輩との連携等）',
      '部下との対話の時間を増やした',
      '目標設定の見直しを実施した',
      '自分自身のWILL/MUST/CANの言語化',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  changeInRelationships: {
    questionNumber: 'Q4',
    question:
      'Q3の結果、部下や自分自身、または部下や上司との関係性において変化したことがあれば教えてください。（複数選択可）',
    type: InputType.SelectMultiple,
    options: [
      '問いかけの引き出しが増えた',
      '部下が自立的にキャリアを考えるようになった',
      '部下のキャリア展望が言語化されるようになった',
      '部下の直近の目標設定が明確になった',
      '部下との関係性が以前よりも深まった',
      '部下のパフォーマンスが向上した',
      'チームのパフォーマンスが向上した',
      '自分自身のWILL/MUST/CANが明確になった',
      '前向きな気持ちで部下と対話できるようになった',
      '部下や上司、同僚からのフィードバックが変わった',
      'その他(自由入力)',
      '特になし',
    ],
    required: true,
    length: {},
  },
  others: {
    questionNumber: 'Q5',
    question: 'その他、ご意見等ございましたらお聞かせください。',
    type: InputType.Text,
    required: false,
    length: { max: 400 },
  },
}

export const HalfYearLaterSurveyDetail = {
  HalfYearLaterSurveyV1: HalfYearLaterSurveyV1Detail,
  HalfYearLaterSurveyV2: HalfYearLaterSurveyV2Detail,
  HalfYearLaterSurveyV3: HalfYearLaterSurveyV3Detail,
}

export const PostSurveyV1DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  changeInAwarenessAndMotivation: '',
  changeInAwarenessAndMotivationReasons: '',
  recommendToOthers: '5',
  recommendToOthersReasons: '',
  willingnessToStepForward: '',
  wasSpeakerHelpful: '',
  wasSpeakerHelpfulReasons: '',
  others: '',
}

export const PostSurveyV2DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  changeInAwarenessAndMotivation: '',
  changeInAwarenessAndMotivationReasons: '',
  recommendToOthers: '5',
  recommendToOthersReasons: '',
  willingnessToStepForward: '',
  others: '',
}

export const PostSurveyV3DefaultValue = {
  understandingOfNeedToSupportSubordinates: '',
  understandingOfStepsSkillsAndMindset: '',
  senseOfStepsSkillsAndMindset: '',
  whatAbleToDo: ['', '', '', '', '', '', '', '', '', '', '', ''],
  whatMakeUseOf: '',
  changeInAwarenessAndMotivation: '',
  changeInAwarenessAndMotivationReasons: '',
  whatDoingInFuture: '',
  wantToConnectWill: '',
  recommendToOthers: '5',
  recommendToOthersReasons: '',
  others: '',
}

export const PostSurveyV4DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  changeInAwarenessAndMotivation: '',
  changeInAwarenessAndMotivationReasons: '',
  recommendToOthers: '5',
  recommendToOthersReasons: '',
  willingnessToStepForward: '',
  wasSpeakerHelpful: '',
  wasSpeakerHelpfulReasons: '',
  others: '',
  willingnessToManage: '',
  positiveReasons: ['', '', '', '', '', '', '', '', '', '', '', ''],
  positiveReasonsOthers: '',
  notKnowingReasons: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  notKnowingReasonsOthers: '',
  negativeReasons: ['', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  negativeReasonsOthers: '',
}

export const PostSurveyV5DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  wantToBuildMyOwnCareer: '',
  wantToBuildMyOwnCareerReasons: '',
  becomeMoreActiveTowardsRealizingMyOwnCareer: '',
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: '',
  wasSpeakerHelpful: '',
  wasSpeakerHelpfulReasons: '',
  others: '',
}

export const PostSurveyV6DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  wantToBuildMyOwnCareer: '',
  wantToBuildMyOwnCareerReasons: '',
  becomeMoreActiveTowardsRealizingMyOwnCareer: '',
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: '',
  others: '',
}

export const PostSurveyV7DefaultValue = {
  careerInventory: '',
  willMustCanVerbalization: '',
  actionPlanVerbalization: '',
  workshopEffectiveness: ['', '', '', '', '', '', '', '', '', '', '', ''],
  workshopEffectivenessReasons: '',
  wantToBuildMyOwnCareer: '',
  wantToBuildMyOwnCareerReasons: '',
  becomeMoreActiveTowardsRealizingMyOwnCareer: '',
  becomeMoreActiveTowardsRealizingMyOwnCareerReasons: '',
  others: '',
}

export const PostSurveyDefaultValue = {
  PostSurveyV1: PostSurveyV1DefaultValue,
  PostSurveyV2: PostSurveyV2DefaultValue,
  PostSurveyV3: PostSurveyV3DefaultValue,
  PostSurveyV4: PostSurveyV4DefaultValue,
  PostSurveyV5: PostSurveyV5DefaultValue,
  PostSurveyV6: PostSurveyV6DefaultValue,
  PostSurveyV7: PostSurveyV7DefaultValue,
}

export const HalfYearLaterSurveyV1DefaultValue = {
  careersOfInterest: ['', '', '', '', '', '', ''],
  careersOfInterestOthers: '',
  changesInAttitudesAndBehavior: '',
  haveTakenAction: '',
  whatWorkingOn: ['', '', '', '', '', '', '', '', '', ''],
  whatWorkingOnOthers: '',
  whatHasChanged: ['', '', '', '', '', '', '', '', '', ''],
  whatHasChangedOthers: '',
  others: '',
}

export const HalfYearLaterSurveyV2DefaultValue = {
  changesInAttitudesAndBehavior: '',
  haveTakenAction: '',
  whatWorkingOn: ['', '', '', '', '', '', '', '', '', ''],
  whatWorkingOnOthers: '',
  whatHasChanged: ['', '', '', '', '', '', '', '', '', ''],
  whatHasChangedOthers: '',
  others: '',
}

export const HalfYearLaterSurveyV3DefaultValue = {
  changesInAttitudesAndBehavior: '',
  haveTakenAction: '',
  whatWorkingOn: ['', '', '', '', '', '', '', '', '', ''],
  whatWorkingOnOthers: '',
  changeInRelationships: ['', '', '', '', '', '', '', '', '', '', '', ''],
  changeInRelationshipsOthers: '',
  others: '',
}

export const HalfYearLaterSurveyDefaultValue = {
  HalfYearLaterSurveyV1: HalfYearLaterSurveyV1DefaultValue,
  HalfYearLaterSurveyV2: HalfYearLaterSurveyV2DefaultValue,
  HalfYearLaterSurveyV3: HalfYearLaterSurveyV3DefaultValue,
}
