import { useRouter } from 'next/router'
import React, { useCallback, createContext, useContext, useState, useEffect } from 'react'
import { RoleOrRoleArray, UserPermissionAttributes } from '@persol-epdndo/base-shared'
import { NotFound } from '../components/NotFound'
import { PrivatePageNavigation } from '../navigations/PrivatePageNavigation'
import _ from 'lodash'

interface AuthContextValue {
  setForbidden: () => void
  pa: UserPermissionAttributes | null
}

const AuthContext = createContext<AuthContextValue>({ setForbidden: () => {}, pa: null })

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext)
}

export interface AuthProps {
  roles: RoleOrRoleArray
  guestOnly: boolean
}

interface AuthProviderProps {
  children: JSX.Element
  authHook: (
    roles: RoleOrRoleArray,
  ) => {
    loading: boolean
    loggedIn: boolean
    authorized: boolean
    pa: UserPermissionAttributes | null
  }
  defaultProps: AuthProps
  pageProps?: Partial<AuthProps>
  isPrivateNavigation: boolean
}

export const LoginPagePath = '/'
export const RedirectPagePath = '/mypage'

export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const router = useRouter()
  const [forbidden, setForbidden] = useState<boolean>(false)
  const authProps = Object.assign({}, props.defaultProps, props.pageProps)
  const { loading, loggedIn, authorized, pa } = useCallback(props.authHook, [])(authProps.roles)

  // URL変更時にforbiddenを初期化する
  useEffect(() => {
    setForbidden(false)
  }, [router.asPath])

  if (loading) {
    return <></>
  }

  // BadRequest画面に遷移するケース(decodeURIComponentでエラー時)ではNotFoundへ遷移させる
  try {
    const url = new URL(document.URL)
    decodeURIComponent(`${url.pathname}${url.search}${url.hash}`)
  } catch {
    return <NotFound isPrivateNavigation={false} />
  }

  if (!loggedIn && authProps.roles.length > 0) {
    const url = new URL(document.URL)
    router.replace(`${LoginPagePath}?ou=${encodeURIComponent(`${url.pathname}${url.search}${url.hash}`)}`)
    return <></>
  }

  if (loggedIn && (!authorized || forbidden)) {
    return (
      <>
        {props.isPrivateNavigation && <PrivatePageNavigation />}
        <NotFound isPrivateNavigation={props.isPrivateNavigation} />
      </>
    )
  }

  if (loggedIn && authProps.guestOnly) {
    const { ou } = router.query
    router.replace(typeof ou === 'string' && !_.isEmpty(ou) ? ou : RedirectPagePath)
    return <></>
  }

  return (
    <AuthContext.Provider
      value={{
        // ナビゲーションが複数存在する場合、useEffect内部で処理する場合に子コンポーネントで呼び出せる
        setForbidden: () => {
          setForbidden(true)
        },
        pa,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
