// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing, @typescript-eslint/strict-boolean-expressions
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || undefined,
  tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE ?? 0),
  integrations: integrations => integrations.filter(integration => integration.name !== 'Dedupe'),
})
Sentry.setTag('package', 'web-client')
