import Head from 'next/head'

interface CommonMetaArgs {
  title?: string
}

export default function CommonMeta({ title }: CommonMetaArgs) {
  return (
    <Head>
      <title>{title !== undefined ? `${title} - CareerMill` : 'CareerMill'}</title>
      <meta name='robots' content='noindex' />
      <link rel='icon' href='/favicon.ico' />
      <link rel='apple-touch-icon-precomposed' href='/apple-touch-icon-precomposed.png' />
    </Head>
  )
}
