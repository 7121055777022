import React, { useCallback } from 'react'
import { AccountCustomTerms } from '@persol-epdndo/base-shared'

export interface AgreementProps {
  onHasNoAgreement: (customTerms: AccountCustomTerms) => React.ReactElement
}

interface AgreementProviderProps {
  children: JSX.Element
  agreementHook: () => {
    loading: boolean
    agreementRequired: boolean
    customTerms: AccountCustomTerms | null
  }
  defaultProps: AgreementProps
  pageProps?: Partial<AgreementProps>
  needToCheckAggrement: boolean
}

export const AgreementProvider = (props: AgreementProviderProps): JSX.Element => {
  if (!props.needToCheckAggrement) {
    return props.children
  }
  const agreementProps = { ...props.defaultProps, ...props.pageProps }
  const { loading, agreementRequired, customTerms } = useCallback(props.agreementHook, [])()

  if (loading) {
    return <></>
  }

  if (agreementRequired && customTerms !== null) {
    return (
      <>
        {agreementProps.onHasNoAgreement(customTerms)}
        {props.children}
      </>
    )
  }

  return props.children
}
