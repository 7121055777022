import React, { FC } from 'react'
import { NotFound as DesignSystemNotFound } from '@persol-epdndo/design-systems'
import { Box } from '@material-ui/core'
import Router from 'next/router'
import CommonMeta from './CommonMeta'
import { useAuthContext } from '../providers/AuthProvider'
import { getTopPage } from '../util'

interface NotFoundProps {
  isPrivateNavigation?: boolean
}

export const NotFound: FC<NotFoundProps> = ({ isPrivateNavigation = true }) => {
  const topPage = getTopPage(useAuthContext().pa)
  return (
    <>
      <CommonMeta title='Not Found' />
      <Box height={isPrivateNavigation ? 'calc(100vh - 64px)' : '100vh'}>
        <DesignSystemNotFound
          onMove={() => {
            Router.push(topPage)
          }}
          buttonLabel='トップページに移動'
        />
      </Box>
    </>
  )
}
export default NotFound
